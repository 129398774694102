<!-- Residential Customer Type Layout -->
<div *ngIf="!customer.customerTypeId || customer.customerTypeId == customerTypesEnum.Residential">
    <div class="p-grid">
        <div class="p-col-12">
            <h4 *ngIf="!smallerHeaders">Contact Info</h4>
        </div>
        <div class="p-col-12 p-md-6" >
            <h6 *ngIf="!smallerHeaders">Type</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Type</div>
            <ng-select class="custom"
                    required
                    bindValue="id"
                    [searchable]="false"
                    [items]="customerTypes"
                    placeholder="Select a Type"
                    [selectOnTab]="true"
                    name="type"
                    bindLabel="name"
                    [(ngModel)]="customer.customerTypeId"
                    [disabled]="!canEdit"
                    (ngModelChange)="typeChange()"
                    *ngIf="customer">
            </ng-select>
        </div>
        <span class="p-md-offset-6"><!-- Spacer to ensure layout is good --></span>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">First Name</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">First Name</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="firstName" [(ngModel)]="customer.customerContacts[primaryContactIndex].firstName"
                *ngIf="customer?.customerContacts[0]" #firstName required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Last Name</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Last Name</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="lastName" [(ngModel)]="customer.customerContacts[primaryContactIndex].lastName"
                *ngIf="customer?.customerContacts[0]" required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Email</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Email</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="emailAddress" (blur)="emailChanged(customer.customerContacts[primaryContactIndex].emailAddress)"
                [(ngModel)]="customer.customerContacts[primaryContactIndex].emailAddress.email" *ngIf="customer?.customerContacts[0]" required email />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Telephone Number</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Telephone Number</div>
            <input
                pInputText
                class="inputTextBox"
                mask="(000) 000-0000"
                placeholder="(     )      -"
                [disabled]="!canEdit"
                [style]="{'border-radius': '5px', 'width': '100%'}"
                name="phoneNumber"
                [(ngModel)]="customer.customerContacts[primaryContactIndex].phoneNumber"
                (ngModelChange)="onPhoneChange()"
                *ngIf="customer?.customerContacts[0]"
            />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Text Notifications</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Text Notifications</div>
            <ng-select class="custom"
                [disabled]="!canEdit || isPhoneNumberEmpty()"
                [required]="!isPhoneNumberEmpty()"
                [items]="textingOptions"
                name="textoption"
                [selectOnTab]="true"
                bindValue="value"
                [clearable]="false"
                [(ngModel)]="customer.customerContacts[primaryContactIndex].textingOptOut"
                *ngIf="customer">
            </ng-select>
        </div>
        <div class="p-col-12 top-padding">
            <h4 *ngIf="!smallerHeaders">Address</h4>
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Billing Address Line 1</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Billing Address Line 1</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="street" [(ngModel)]="customer.address.street"
                *ngIf="customer" required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Billing Address Line 2</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Billing Address Line 2</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="apt" [(ngModel)]="customer.address.apartment"
                *ngIf="customer" />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">City</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">City</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="city" [(ngModel)]="customer.address.city"
                *ngIf="customer" required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">State</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">State</div>
            <ng-select class="custom" [disabled]="!canEdit" required [items]="states" placeholder="Select a State" [selectOnTab]="true"
                    (ngModelChange)="onStateSelect($event)" name="state" bindLabel="name" [(ngModel)]="selectedState"
                    *ngIf="customer">
            </ng-select>
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Zip</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Zip</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="zip" [(ngModel)]="customer.address.zip"
                *ngIf="customer" required />
        </div>
        <span class="p-md-offset-6"><!-- Spacer to ensure layout is good --></span>
        <div class="p-col-12 p-md-4" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Active</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Active</div>
            <p-checkbox binary="true" [disabled]="!canEdit" name="active" [(ngModel)]="customer.active" *ngIf="customer">
            </p-checkbox>
        </div>
        <div class="p-col-12 p-md-4" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Tax Exempt</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Tax Exempt</div>
            <p-checkbox binary="true" name="taxExempt" [(ngModel)]="customer.taxExempt" [disabled]="!canEdit || isCustomer" *ngIf="customer"></p-checkbox>
        </div>
        <div class="p-col-12 p-md-4" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Payment Info Required</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Payment Info Required</div>
            <p-checkbox binary="true" name="paymentInfoRequired" [(ngModel)]="customer.paymentInfoRequired" [disabled]="!canEdit || isCustomer || !canEditBilling" *ngIf="customer" (click)="paymentInfoRequiredChange()"></p-checkbox>
        </div>
    </div>
</div>
<!-- Non-Residential Customer Type Layout -->
<div *ngIf="customer.customerTypeId && customer.customerTypeId != customerTypesEnum.Residential">
    <div class="p-grid">
        <div class="p-col-12">
            <h4 *ngIf="!smallerHeaders">Contact Info</h4>
        </div>
        <div class="p-col-12 p-md-6" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Type</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Type</div>
            <ng-select class="custom"
                    required
                    bindValue="id"
                    [searchable]="false"
                    [items]="customerTypes"
                    placeholder="Select a Type"
                    [selectOnTab]="true"
                    name="type"
                    bindLabel="name"
                    [(ngModel)]="customer.customerTypeId"
                    [disabled]="!canEdit"
                    (ngModelChange)="typeChange()"
                    *ngIf="customer">
            </ng-select>
        </div>
        <div class="p-col-12">
            <h6 *ngIf="!smallerHeaders">Company Name</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Company Name</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="companyName" [(ngModel)]="customer.companyName"
                *ngIf="customer" #companyName required />
        </div>
        <div class="p-col-12 p-md-4" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Active</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Active</div>
            <p-checkbox binary="true" [disabled]="!canEdit" name="active" [(ngModel)]="customer.active" *ngIf="customer">
            </p-checkbox>
        </div>
        <div class="p-col-12 p-md-4">
            <h6 *ngIf="!smallerHeaders">Tax Exempt</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Tax Exempt</div>
            <p-checkbox binary="true" name="taxExempt" [(ngModel)]="customer.taxExempt" [disabled]="!canEdit || isCustomer" *ngIf="customer"></p-checkbox>
        </div>
        <div class="p-col-12 p-md-4" *ngIf="!isCustomer">
            <h6 *ngIf="!smallerHeaders">Payment Info Required</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Payment Info Required</div>
            <p-checkbox binary="true" name="paymentInfoRequired" [(ngModel)]="customer.paymentInfoRequired" [disabled]="!canEdit || isCustomer || !canEditBilling" *ngIf="customer" (onChange)="paymentInfoRequiredChange()"></p-checkbox>
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Billing Address</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Billing Address</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="street" [(ngModel)]="customer.address.street"
                *ngIf="customer" required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">City</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">City</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="city" [(ngModel)]="customer.address.city"
                *ngIf="customer" required />
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">State</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">State</div>
            <ng-select class="custom" [disabled]="!canEdit" required [items]="states" placeholder="Select a State" [selectOnTab]="true"
                    (ngModelChange)="onStateSelect($event)" name="state" bindLabel="name" [(ngModel)]="selectedState"
                    *ngIf="customer">
            </ng-select>
        </div>
        <div class="p-col-12 p-md-6">
            <h6 *ngIf="!smallerHeaders">Zip</h6>
            <div *ngIf="smallerHeaders" class="rfq-header">Zip</div>
            <input pInputText [disabled]="!canEdit" class="inputTextBox" name="zip" [(ngModel)]="customer.address.zip"
                *ngIf="customer" required />
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12 p-md-6" *ngIf="customer?.createdDate">
        <h6 *ngIf="!smallerHeaders">Created</h6>
        <div *ngIf="smallerHeaders" class="rfq-header">Created</div>
        {{customer.createdDate | date: 'shortDate'}}
    </div>
</div>
<div>
    <div *ngIf="customer.customerTypeId && customer.customerTypeId != customerTypesEnum.Residential">
        <div class="p-grid columnPadding" style="padding-bottom:0px;">
            <div class="p-col-12 p-sm-12">
                <h6 *ngIf="!smallerHeaders">Main Contact</h6>
                <div *ngIf="smallerHeaders" class="rfq-header">Main Contact</div>
            </div>
        </div>
        <app-customer-contact 
            [customerContact]="customer.customerContacts[primaryContactIndex]" 
            [canEdit]="canEdit"
            [customerTypeId]="customer.customerTypeId" (deleted)="deleteContact($event)"
            [smallerHeaders]="smallerHeaders">
        </app-customer-contact>
    </div>
    <div *ngIf="customer.customerContacts.length > 1" >
        <div class="p-grid columnPadding" style="padding-bottom:0px;">
            <div class="p-col-12 p-sm-12">
                <h6 *ngIf="!smallerHeaders">Additional Contact(s)</h6>
                <div *ngIf="smallerHeaders" class="rfq-header">Additional Contact(s)</div>
            </div>
        </div>
        <div *ngFor="let contact of customer.customerContacts; let i = index">
            <div *ngIf="i != primaryContactIndex" class="contact-container">
                <app-customer-contact 
                    *ngIf="customer.customerContacts[i].active" 
                    [customerContact]="customer.customerContacts[i]" 
                    [canEdit]="canEdit" 
                    [customerTypeId]="customer.customerTypeId"
                    [smallerHeaders]="smallerHeaders"
                    (deleted)="deleteContact($event)">
                </app-customer-contact>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12" style="padding-top: 15px;">
            <button *ngIf="!isCustomer && !hideAddContact" pButton type="button" class="ui-button-raised ui-button-success buttonStyle"
                [disabled]="!canEdit" label="Add Contact" (click)="addContact(false)" style="float: left;"></button>
        </div>
    </div>
</div>
