import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DecodedJwt } from '../models/decodedJwt';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { LogViewModel, SwaggerException } from '../services_autogenerated/generated_services';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-pwa/local-storage";
import * as i4 from "./app-config.service";
var AuthHelperService = /** @class */ (function () {
    function AuthHelperService(http, router, localStorage, route, appConfigService) {
        this.http = http;
        this.router = router;
        this.localStorage = localStorage;
        this.route = route;
        this.appConfigService = appConfigService;
        this.isLoggedInSubject = new Subject();
        this.authToken = 'russell_tree_auth_token';
        this.jsonParseReviver = undefined;
    }
    AuthHelperService.prototype.logging = function (logData) {
        var _this = this;
        var url_ = this.appConfigService.appConfig.apiUrl + "/api/v1/log";
        var content_ = JSON.stringify(logData);
        var options_ = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
        return this.http.request("post", url_, options_).pipe(_observableMergeMap(function (response_) {
            return _this.processAdd(response_);
        })).pipe(_observableCatch(function (response_) {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return _this.processAdd(response_);
                }
                catch (e) {
                    return _observableThrow(e);
                }
            }
            else
                return _observableThrow(response_);
        }));
    };
    AuthHelperService.prototype.processAdd = function (response) {
        var _this = this;
        var status = response.status;
        var responseBlob = response instanceof HttpResponse ? response.body :
            response.error instanceof Blob ? response.error : undefined;
        var _headers = {};
        if (response.headers) {
            for (var _i = 0, _a = response.headers.keys(); _i < _a.length; _i++) {
                var key = _a[_i];
                _headers[key] = response.headers.get(key);
            }
        }
        ;
        if (status === 200) {
            return this.blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : null;
                return _observableOf(result200);
            }));
        }
        else if (status !== 200 && status !== 204) {
            return this.blobToText(responseBlob).pipe(_observableMergeMap(function (_responseText) {
                return _this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(null);
    };
    AuthHelperService.prototype.throwException = function (message, status, response, headers, result) {
        if (result !== null && result !== undefined)
            return _observableThrow(result);
        else
            return _observableThrow(new SwaggerException(message, status, response, headers, null));
    };
    AuthHelperService.prototype.blobToText = function (blob) {
        return new Observable(function (observer) {
            if (!blob) {
                observer.next("");
                observer.complete();
            }
            else {
                var reader = new FileReader();
                reader.onload = function (event) {
                    observer.next(event.target.result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        });
    };
    AuthHelperService.prototype.logOut = function () {
        localStorage.removeItem(this.authToken);
        this.router.navigate(['/login']);
        this.isLoggedInSubject.next(false);
    };
    AuthHelperService.prototype.getDecodedAccessToken = function (token) {
        try {
            if (!token) {
                token = localStorage.getItem(this.authToken);
            }
            var decodedToken = jwt_decode(token);
            return new DecodedJwt(decodedToken);
        }
        catch (Error) {
            return null;
        }
    };
    AuthHelperService.prototype.isLoggedIn = function () {
        var token = this.getDecodedAccessToken();
        var notExpired = token && new Date(token.exp * 1000) > new Date(); // change to ms then date
        if (!notExpired) { // makes notExpired non null
            notExpired = false;
        }
        this.isLoggedInSubject.next(notExpired);
        return notExpired;
    };
    AuthHelperService.prototype.redirectToHome = function () {
        var token = this.getDecodedAccessToken();
        if (this.requiresPasswordReset(token)) {
            if (this.route.snapshot.queryParams.asAnonymous) {
                var extras = {
                    queryParams: { 'asAnonymous': this.route.snapshot.queryParams.asAnonymous }
                };
                this.router.navigate(['/passwordChange'], extras);
            }
            else {
                this.router.navigate(['/passwordChange']);
            }
        }
        else if (token.userType === 'customer') {
            this.router.navigate(['/customerLandingPage/' + token.id]);
        }
        else if (token.roles.includes('Arborist') || token.roles.includes('Foreman')) {
            this.router.navigate(['/scheduling/myschedule']);
        }
        else {
            this.router.navigate(['/welcome']);
        }
        this.isLoggedInSubject.next(true);
    };
    AuthHelperService.prototype.requiresPasswordReset = function (token) {
        if (token.requirePasswordReset === 'True') {
            return true;
        }
        else if (token.passwordIsExpired === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.requiresMultiFactorAuthSetup = function (token) {
        if (token.requireMultiFactorAuthSetup === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.requiresMultiFactorAuth = function (token) {
        if (token.requireMultiFactorAuth === 'True') {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.prototype.hasClaim = function (claim) {
        var token = this.getDecodedAccessToken();
        if (Object.keys(token).indexOf(claim) >= 0) {
            return true;
        }
        else {
            return false;
        }
    };
    AuthHelperService.ngInjectableDef = i0.defineInjectable({ factory: function AuthHelperService_Factory() { return new AuthHelperService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.LocalStorage), i0.inject(i2.ActivatedRoute), i0.inject(i4.AppConfigService)); }, token: AuthHelperService, providedIn: "root" });
    return AuthHelperService;
}());
export { AuthHelperService };
