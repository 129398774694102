import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { InvoiceChargeViewModel, StripeGenSvc, WellnessWorkOrderGenSvc, WorkOrderInvoicingViewModel, WorkWorkOrderGenSvc } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-refund-invoice',
  templateUrl: './refund-invoice.component.html',
  styleUrls: ['./refund-invoice.component.css']
})
export class RefundInvoiceComponent implements OnInit {
  @Input() showDialog: boolean;
  @Input() workOrder: WorkOrderInvoicingViewModel;

  @Output() close  = new EventEmitter<boolean>();

  charges: InvoiceChargeViewModel[];
  isLoading: boolean;

  token: any;

  constructor(private customStripeService: StripeGenSvc,
    private messageService: MessageService,
    private workWorkOrderService: WorkWorkOrderGenSvc,
    private wellnessWorkOrderService: WellnessWorkOrderGenSvc,
    private authHelperService: AuthHelperService) { }

  ngOnInit() {
    this.token = this.authHelperService.getDecodedAccessToken();
    this.isLoading = true;
    const type = this.workOrder.serviceType === 'Tree Wellness' ? 'Wellness' : 'Work';
    this.customStripeService.getAllInvoicesForWorkOrder(this.workOrder.workOrderId, type).subscribe(res => {
      this.isLoading = false;
      this.charges = res;
    }, error => {
      this.isLoading = false;
      console.log(error);
      this.addErrorMessage('Unexpected error, please reload and try again.');
    });
  }

  refund(charge: InvoiceChargeViewModel, toRecharge: boolean) {
    if (!toRecharge && !confirm('You will not be able to charge the customer again if you select this option. If you need to charge the customer with a different card or adjust the price, please select [Refund to Recharge]')) {
      return;
    }
    charge.recharge = toRecharge;
    this.customStripeService.refundCharge(charge).subscribe(res => {
      var woId = charge.quoteNumber.split('-')[2].replace('s', '').replace('k', '');
      var empId = this.token.employeeId
      var message = 'Work order was uncompleted during the Refund to Recharge process.';
      if (charge.quoteNumber.endsWith('s')) {
        this.wellnessWorkOrderService.undoWorkOrderCompletion(+woId, message, empId).subscribe(res => {
        window.open('wellnessWorkOrderMaintenance/' + woId, '_blank');
          this.close.emit(true);
        }, error => {
          console.log(error);
          this.addErrorMessage('Refund was issued, but WO could not be uncompleted. Please go to the WO maintenance page to review.');
        });
      } else if (charge.quoteNumber.endsWith('k')) {
        window.open('workWorkOrderMaintenance/' + woId, '_blank');
        this.workWorkOrderService.undoWorkOrderCompletion(+woId, message, empId).subscribe(res => {
          this.close.emit(true);
        }, error => {
          console.log(error);
          this.addErrorMessage('Refund was issued, but WO could not be uncompleted. Please go to the WO maintenance page to review.');
        });
      }

    }, error => {
      console.log(error);
      this.addErrorMessage('Unable to issue refund, please try again in Stripe.');
    });
  }

  cancel() {
    this.close.emit(false);
  }

  private addErrorMessage(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
  }
}
