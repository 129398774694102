<p-dialog class="scrollableDialog" [header]="workOrder.serviceType + ': ' + workOrder.number" [(visible)]="showDialog" [modal]="true" [blockScroll]="false" (onHide)="closeAndRefresh()" [style]="{width: '515px'}">
  <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
  <span *ngIf="!isLoading">
    <h6>Cash Collected:</h6>
    <span *ngIf="cashCollected.length == 0" class="fine-print" style="margin-bottom: 5px;">No Cash or Check Collected</span>
    <div class="container" *ngFor="let cash of cashCollected; let i = index"> 
      <label for="total" style="margin: auto;"><span class="totalLabel">Value of Cash or Check Collected</span>&nbsp;&nbsp;</label>
      <input
        pInputText 
        [name]="'cashCollected' + i" 
        [textMask]="{mask: currencyMask, placeholderChar: '\u2000'}" 
        [ngModel]="cash.amount" 
        [disabled]="!(!workOrder.paymentStatus || workOrder.paymentStatus === 'Not Paid') || cash.invoiced || disableApply"
        class="totalBoxes" 
        *ngIf="workOrder"
        (ngModelChange)="cashCollectedChange($event, i)"
        required
      />
      <button pButton
        [disabled]="cash.invoiced || (workOrder.paymentStatus && workOrder.paymentStatus !== 'Not Paid') || disableApply"
        type="button"
        class="ui-button-raised ui-button-danger"
        (click)="removeCashCollectedEntry(i)"
        icon="pi pi-trash"
        style="margin-left:4px">
      </button>
    </div>
    <div class="inner-container">
      <button pButton
        *ngIf="!this.workOrder.paymentStatus || this.workOrder.paymentStatus === 'Not Paid'"
        [disabled]="disableApply"
        type="button"
        class="ui-button-primary"
        (click)="pushNewCashCollectedEntry()"
        label="Add Cash  Collected">
      </button>
      <button pButton
        *ngIf="!this.workOrder.paymentStatus || this.workOrder.paymentStatus === 'Not Paid'"
        [disabled]="disableApply"
        type="button"
        class="ui-button-success"
        (click)="saveCashCollectedEntry()"
        label="Save Cash Collected">
      </button>
    </div>
    <hr/>
    <h6>Invoices:</h6>
    <div class="container" *ngFor="let invoice of workOrder.invoices">
      <div class="bold header-line">
        Invoice: <span class="regular-link" (click)="navigate(invoice.invoiceUrl)">{{invoice.invoiceNumber}}</span>
        <span style="float: right;">Status: {{invoice.status | titlecase}}</span>
      </div><br/>
      <div>Created: {{invoice.created | date: 'shortDate'}}</div>
      <div>Amount Due: {{invoice.amountDue | currency: 'USD'}}</div>
      <div>Amount Paid: {{invoice.amountPaid | currency: 'USD'}}</div>
      <div *ngIf="invoice.datePaid">Paid On: {{invoice.datePaid | date: 'shortDate' }}</div>
      <div *ngIf="invoice.refunded || invoice.amountRefunded > 0"></div>
      <div *ngIf="invoice.refunded">Amount Refunded: {{invoice.amountRefunded | currency: 'USD' }}</div>
      <div *ngIf="invoice.status == 'open'" class="inner-container">
        <ng-select
          #selectDropdown
          style="min-width: 200px"
          class="custom"
          appendTo="body"
          [selectOnTab]="true"
          [items]="nonInvoicedCashCollected"
          placeholder="Select a Cash Amount"
          name="cashAmount"
          (change)="setCashIdToApply(invoice, $event)"
          bindLabel="amount">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{item.amount | currency: 'USD'}}
          </ng-template>
        </ng-select>
        <button 
          style="border-radius: 5px;" 
          pButton type="button" 
          class="ui-button-success" 
          label="Apply Now" 
          [disabled]="disableApply"
          (click)="applyCashToInvoice(invoice)">
        </button>
        <div *ngIf="invoice.status == 'open'" class="charge-container">
          <button 
            style="border-radius: 5px; align-self: flex-end;" 
            pButton type="button" 
            class="ui-button-warning" 
            label="Charge Card" 
            [disabled]="disableApply"
            (click)="chargeCardForInvoice(invoice)">
          </button>
        </div>
      </div>
    </div>
  </span>
  <p-footer>
    <button type="button" pButton (click)="closeAndRefresh()" label="Close" class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>