import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
    WellnessTaskTreeType,
    SkillGenSvc,
    TreeTypeGenSvc,
    WellnessTask,
    WellnessTaskTemplateGenSvc,
    WellnessTaskTemplate,
    WellnessWorkOrder,
    WellnessTaskGenSvc,
    WorkOrderWellnessTask,
    PriorityLevelGenSvc,
    WellnessTaskSkill,
    WellnessTaskEquipment,
    QuoteWorkOrder,
    EquipmentTypeGenSvc,
    QuoteWorkOrderCompletionStatus,
    WellnessTaskCompletionStatus,
    WellnessWorkOrderCompletionStatus,
    WellnessTaskPaymentStatus,
    WellnessTaskGoBackEquipment,
    WellnessTaskUpdateDTO,
    WellnessTaskUpdateDTOManualPriorityShortcutType,
    WellnessTaskUpdateDTOCompletionStatus} from '../../services_autogenerated/generated_services';
import { MaskService } from 'src/app/services/mask.service';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseTaskCardMaintenanceComponent } from '../base-task-card-maintenance/base-task-card-maintenance.component';
import * as moment from 'moment';
import { PriorityAndDateSelectorComponent } from '../priority-and-date-selector/priority-and-date-selector.component';

@Component({
  selector: 'app-wellness-task-maintenance',
  templateUrl: './wellness-task-maintenance.component.html',
  styleUrls: ['./wellness-task-maintenance.component.css']
})
export class WellnessTaskMaintenanceComponent extends BaseTaskCardMaintenanceComponent implements OnInit {
  @ViewChild('prioritySelector') prioritySelector: PriorityAndDateSelectorComponent;
  @Input() task: WellnessTask;
  @Input() workOrder: WellnessWorkOrder | QuoteWorkOrder;
  @Input() showRangeCalculationOption: boolean = false;

  equipmentOptions: WellnessTaskEquipment[];
  goBackEquipmentOptions: WellnessTaskEquipment[];
  dependencyOptions: WellnessTask[];
  skillOptions: WellnessTaskSkill[];
  templateOptions: WellnessTaskTemplate[];
  isInvoiced = false;

  canChangeStatus: boolean;
  isIncompleteGoBack: boolean;
  statuses: string[] = [WellnessTaskCompletionStatus.Completed];

  limitEditing: boolean;

  constructor(
    skillService: SkillGenSvc,
    equipmentTypeService: EquipmentTypeGenSvc,
    treeTypeService: TreeTypeGenSvc,
    priorityLevelService: PriorityLevelGenSvc,
    messageService: MessageService,
    maskService: MaskService,
    authHelper: AuthHelperService,
    private templateService: WellnessTaskTemplateGenSvc,
    private wellnessTaskService: WellnessTaskGenSvc,
  ) {
      super(skillService,
        equipmentTypeService,
        treeTypeService,
        priorityLevelService,
        messageService,
        maskService,
        authHelper);
  }

  ngOnInit() {
    this.currencyMask = this.maskService.currencyMaskNoDecimal;
    this.isQuoteTask = this.workOrder instanceof QuoteWorkOrder;

    if (!this.task) {
      const task = new WellnessTask();
      task.active = true;
      task.renewable = true;
      task.renewalAccepted = false;
      task.renewed = false;
      task.completionStatus = this.workOrder instanceof WellnessWorkOrder ?
        WellnessTaskCompletionStatus.Ready_to_Schedule :
        WellnessTaskCompletionStatus.New;
      task.fixedPrice = true;
      task.taskHourEntries = [];
      task.wellnessTaskChemicalCombos = [];
      task.wellnessTaskEquipment = [];
      task.wellnessTaskSkills = [];
      task.wellnessTaskTreeTypes = [];
      this.task = task;

      if (this.workOrder instanceof QuoteWorkOrder) {
        // defaults to the quote's priority type/dates
        this.task.hasCalculatedPriority = this.workOrder.hasCalculatedPriority;
        this.task.dueDateStart = this.workOrder.dueDateStart;
        this.task.dueDateEnd = this.workOrder.dueDateEnd;
        this.task.hardStartDate = this.workOrder.hardStartDate;
        this.task.hardEndDate = this.workOrder.hardEndDate;

        this.task.manualPriorityShortcutType = this.workOrder.manualPriorityShortcutType as any;
        this.task.manualRangeEnd = this.workOrder.manualRangeEnd;
        this.task.manualRangeStart = this.workOrder.manualRangeStart;

        this.task.manualPriorityLevel = this.workOrder.manualPriorityLevel;
        this.task.priorityLevelId = this.workOrder.priorityLevelId;
        this.task.priorityShortcutId = this.workOrder.priorityShortcutId;
        this.task.priorityTypeShortcut = this.workOrder.priorityTypeShortcut;
      } else if (this.workOrder.workOrderWellnessTasks.length > 0) {
        const firstTaskOnWO = this.workOrder.workOrderWellnessTasks[0].wellnessTask;
        this.task.hasCalculatedPriority = firstTaskOnWO.hasCalculatedPriority;
        this.task.dueDateStart = firstTaskOnWO.dueDateStart;
        this.task.dueDateEnd = firstTaskOnWO.dueDateEnd;
        this.task.hardStartDate = firstTaskOnWO.hardStartDate;
        this.task.hardEndDate = firstTaskOnWO.hardEndDate;

        this.task.manualPriorityShortcutType = firstTaskOnWO.manualPriorityShortcutType as any;
        this.task.manualRangeEnd = firstTaskOnWO.manualRangeEnd;
        this.task.manualRangeStart = firstTaskOnWO.manualRangeStart;

        this.task.manualPriorityLevel = firstTaskOnWO.manualPriorityLevel;
        this.task.priorityLevelId = firstTaskOnWO.priorityLevelId;
        this.task.priorityShortcutId = firstTaskOnWO.priorityShortcutId;
        this.task.priorityTypeShortcut = firstTaskOnWO.priorityTypeShortcut;
      }
    } else {
      this.copyTask();
      this.isInvoiced = this.task.paymentStatus === WellnessTaskPaymentStatus.Invoiced || this.task.paymentStatus === WellnessTaskPaymentStatus.Paid;
    }

    this.setUploadId();

    this.dependencyOptions = this.workOrder.workOrderWellnessTasks
      .map(wowt => wowt.wellnessTask)
      .filter(wt => wt.active && wt !== this.task);

    const claims = this.authHelper.getDecodedAccessToken().claimNames;
    this.limitEditing = claims.includes('Edit Assigned Tasks');
    this.isEditable = (
                        this.task.completionStatus !== WellnessTaskCompletionStatus.Completed
                        && this.task.completionStatus !== WellnessTaskCompletionStatus.Go_Back
                        && this.task.completionStatus !== WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                      )
      && ((this.task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed
      && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
      && this.workOrder instanceof WellnessWorkOrder ?
        this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed :
        this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created)
        || claims.includes('Full Control'));

    this.canEditUploads = claims.includes('View/Edit Work Order');
    this.canComplete = this.task.id && this.task.completionStatus !== WellnessTaskCompletionStatus.Completed
      && this.task.scheduleDateFrom !== undefined
      && this.isEditable && claims.includes('Completing a Task')
      && this.task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed;

      this.isIncompleteGoBack = this.task.completionStatus !== WellnessTaskCompletionStatus.Completed && this.task.isGoBack;
      if (claims.includes('Can Initiate Go Back')) {
        this.statuses.push(WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
        this.canChangeStatus = this.task.completionStatus === WellnessTaskCompletionStatus.Completed
                              || this.task.completionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                              || this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back
                              || this.isIncompleteGoBack;
      }

      if (claims.includes('Can Set Go Back')) {
        this.statuses.push(WellnessTaskCompletionStatus.Go_Back);
        this.canChangeStatus = this.task.completionStatus === WellnessTaskCompletionStatus.Completed
                              || this.task.completionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                              || this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back
                              || this.isIncompleteGoBack;
      }
    this.templateService.getAllActive().subscribe(templates => {
      this.templateOptions = templates;
    });

    this.skillService.getAll().subscribe(skills => {
      if (this.task.wellnessTaskSkills) {
        this.skillOptions = this.task.wellnessTaskSkills;
      } else {
        this.skillOptions = [];
        this.task.wellnessTaskSkills = [];
      }

      const otherSkills = skills.filter(s => !this.task.wellnessTaskSkills.some(wts => wts.skillId === s.id));

      this.skillOptions = this.skillOptions.concat(otherSkills.map(skill => {
        return new WellnessTaskSkill({ skill: skill, skillId: skill.id, wellnessTaskId: this.task.id });
      }));
    });

    this.equipmentTypeService.getActive().subscribe(equipment => {
      if (this.task.wellnessTaskEquipment) {
        this.equipmentOptions = this.task.wellnessTaskEquipment;
      } else {
        this.equipmentOptions = [];
        this.task.wellnessTaskEquipment = [];
      }

      if (this.task.wellnessTaskGoBackEquipment) {
        this.goBackEquipmentOptions = this.task.wellnessTaskGoBackEquipment;
      } else {
        this.goBackEquipmentOptions = [];
        this.task.wellnessTaskGoBackEquipment = [];
      }

      const otherEquipment = equipment.filter(e =>
        !this.task.wellnessTaskEquipment.some(wte => wte.equipmentTypeId === e.id));
      const otherGoBackEquipment = equipment.filter(e => !this.task.wellnessTaskGoBackEquipment.some(wte => wte.goBackEquipmentId === e.id));

      this.equipmentOptions = this.equipmentOptions.concat(otherEquipment.map(equip => {
        return new WellnessTaskEquipment({ equipmentType: equip, equipmentTypeId: equip.id, wellnessTaskId: this.task.id
        });
      }));

      this.goBackEquipmentOptions = this.goBackEquipmentOptions.concat(otherGoBackEquipment.map(equip => {
        return new WellnessTaskGoBackEquipment({ equipmentType: equip, goBackEquipmentId: equip.id, wellnessTaskId: this.task.id
        });
      }));
    });

    this.treeTypeService.getAll().subscribe(types => {
      if (this.task.wellnessTaskTreeTypes) {
        this.treeTypeOptions = this.task.wellnessTaskTreeTypes; // start with existing types
      } else {
        this.treeTypeOptions = [];
        this.task.wellnessTaskTreeTypes = [];
      }

      // add other options from types they could pick from
      const otherTypes = types.filter(t =>
        !this.task.wellnessTaskTreeTypes.some(wttt => wttt.treeTypeId === t.id));

      this.treeTypeOptions = this.treeTypeOptions.concat(otherTypes.map(tt => {
        const newWTTT = new WellnessTaskTreeType();
        newWTTT.treeType = tt;
        newWTTT.treeTypeId = tt.id;
        newWTTT.wellnessTaskId = this.task.id; // may be null if new equipment
        return newWTTT;
      }));
      this.treeTypeOptions = this.treeTypeOptions.map(tto => {
        return {
          label: tto.treeType.genus ?
                `${tto.treeType.commonName} (${tto.treeType.genus} ${tto.treeType.species})` :
                `${tto.treeType.commonName}`,
          value: tto
        };
      });
    });
  }

  completeTask() {
    this.task.completionStatus = WellnessTaskCompletionStatus.Completed;
    if (!this.task.completedDate) {
      this.task.completedDate = new Date();
    }
  }

  onSchedulableAfterCompletionOfIdChange() {
    if (!this.task.schedulableAfterCompletionOfId) {
      this.task.schedulableAfterCompletionOf = null;
    }
  }

  saveTask(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });

    if (!ngForm.invalid) {
      if (this.task.id) {
        const dto = new WellnessTaskUpdateDTO({
          id: this.task.id,
          name: this.task.name,
          price: this.task.price,
          hours: this.task.hours,
          description: this.task.description,
          treeTypeIds: this.task.wellnessTaskTreeTypes.map(wttt => wttt.treeTypeId),
          location: this.task.location,
          skillIds: this.task.wellnessTaskSkills.map(wts => wts.skillId),
          equipmentTypeIds: this.task.wellnessTaskEquipment.map(wte => wte.equipmentTypeId),
          schedulableAfterCompletionOfId: this.task.schedulableAfterCompletionOfId,
          completionStatus: this.dumbEnumConversion(this.task.completionStatus), // kinda strange that WellnessTaskUpdateDTOCompletionStatus[this.task.completionStatus] didn't work
          customerIssue: this.task.customerIssue,
          goBackHoursEstimate: this.task.goBackHoursEstimate,
          goBackEquipmentTypeIds: this.task.wellnessTaskGoBackEquipment.map(wtgbe => wtgbe.goBackEquipmentId),
          // priority stuff
          priorityLevelId: this.task.manualPriorityLevel ? this.task.manualPriorityLevel.id : null,
          hasCalculatedPriority: this.task.hasCalculatedPriority,
          priorityShortcutId: this.task.priorityShortcutId,
          dueDateStart: this.task.dueDateStart,
          dueDateEnd: this.task.dueDateEnd,
          hardStartDate: this.task.hardStartDate,
          hardEndDate: this.task.hardEndDate,
          manualPriorityShortcutType: WellnessTaskUpdateDTOManualPriorityShortcutType[this.task.manualPriorityShortcutType],
          manualRangeStart: this.task.manualRangeStart,
          manualRangeEnd: this.task.manualRangeEnd,
          // wellness task only updates
          renewable: this.task.renewable,
          renewalAccepted: this.task.renewalAccepted,
          renewed: this.task.renewed,
          growingDegreeDay: this.task.growingDegreeDay,
        });
        this.isSaving = true;
        this.wellnessTaskService.updateWithDTO(dto).subscribe(wellnessTask => {
          if (this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back) {
            this.task.hasCalculatedPriority = false;
          }

          this.saveBlobs(wellnessTask);
          this.task = wellnessTask;
        }, error => {
          this.isSaving = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Could not save the task, please ensure all the fields are valid and retry.'
          });
        });
      } else {
        this.isSaving = true;
        // Save the wellness task
        this.wellnessTaskService.add(this.task).subscribe(wellnessTask => {
          this.task = wellnessTask;
          const woWellnessTask = new WorkOrderWellnessTask();
          woWellnessTask.wellnessTask = wellnessTask;
          woWellnessTask.wellnessTaskId = wellnessTask.id;

          if (this.workOrder instanceof WellnessWorkOrder) {
            woWellnessTask.wellnessWorkOrderId = this.workOrder.id;
            if (this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed) {
              // We are adding a task to completed WO (it's probably a go back) so change the WO's completion status
              this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;
            }

            if (this.task.priorityShortcutId) {
              const backlog = this.prioritySelector.priorityTypeOptions.find(p => p.id === this.task.priorityShortcutId);

              if (backlog && backlog.weeksOutStart !== undefined) {
                let dueDateStart = new Date();
                let dueDateEnd = new Date();

                if (this.workOrder.workOrderWellnessTasks.length > 0 && this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTask.priorityShortcutId === 3)) {
                  dueDateStart = this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTask.priorityShortcutId === 3).wellnessTask.dueDateStart;
                  dueDateEnd = this.workOrder.workOrderWellnessTasks.find(wowt => wowt.wellnessTask.priorityShortcutId === 3).wellnessTask.dueDateEnd;
                } else if (backlog) {
                  const daysOutStart = (backlog.weeksOutStart !== undefined ? backlog.weeksOutStart : 4) * 7;
                  const daysOutEnd = (backlog.weeksOutEnd !== undefined ? backlog.weeksOutEnd : 5) * 7;

                  dueDateStart = moment(dueDateStart).add(daysOutStart, 'd').toDate();
                  dueDateEnd = moment(dueDateEnd).add(daysOutEnd, 'd').toDate();
                }

                this.task.dueDateStart = dueDateStart;
                this.task.dueDateEnd = dueDateEnd;
              }
            }
          } else {
            woWellnessTask.quoteWorkOrderId = this.workOrder.id;
          }

          this.workOrder.workOrderWellnessTasks.push(woWellnessTask);

          this.saveBlobs(wellnessTask);
        }, error => {
          this.isSaving = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Could not add the task to the work order, please ensure all the fields are valid and retry.'
          });
        });
      }
    }
  }

  deleteButtonPressed() {
    // if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
    //   const deleteIndex = this.workOrder.workOrderWellnessTasks.findIndex(wt => wt.wellnessTask.id === this.task.id);
    //   const deleted = this.workOrder.workOrderWellnessTasks[deleteIndex];
    //   if (deleted.id) {
    //     this.wellnessTaskService.delete(this.task.id).subscribe(() => {
    //       this.workOrder.workOrderWellnessTasks.splice(deleteIndex, 1);
    //       this.wellnessWorkOrderMaintenanceService.removeWellnessTaskDependentReferences(this.workOrder, deleted);
    //     });
    //   } else {
    //     this.workOrder.workOrderWellnessTasks.splice(deleteIndex, 1);
    //     this.wellnessWorkOrderMaintenanceService.removeWellnessTaskDependentReferences(this.workOrder, deleted);
    //   }
    // }
    this.delete.emit(this.task);
  }
  
  dumbEnumConversion(wellnessTaskCompletionStatus: WellnessTaskCompletionStatus): WellnessTaskUpdateDTOCompletionStatus {
    // most common ones on top
    if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up) {
      return WellnessTaskUpdateDTOCompletionStatus.Customer_Unsatisfied__RM_Follow_Up;
    } else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Go_Back) {
      return WellnessTaskUpdateDTOCompletionStatus.Go_Back;
    } else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Completed) {
      return WellnessTaskUpdateDTOCompletionStatus.Completed;
    } else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Created) {
      return WellnessTaskUpdateDTOCompletionStatus.Created;
    } else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Ready_to_Schedule) {
      return WellnessTaskUpdateDTOCompletionStatus.Ready_to_Schedule;
    } else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
      return WellnessTaskUpdateDTOCompletionStatus.Customer_Not_Notified;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Notified) {
      return WellnessTaskUpdateDTOCompletionStatus.Customer_Notified;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Partially_Complete) {
      return WellnessTaskUpdateDTOCompletionStatus.Partially_Complete;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed) {
      return WellnessTaskUpdateDTOCompletionStatus.Credit_Card_Needed;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Work_Orders_Created) {
      return WellnessTaskUpdateDTOCompletionStatus.Work_Orders_Created;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed) {
      return WellnessTaskUpdateDTOCompletionStatus.Unable_to_be_Completed;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Paid) {
      return WellnessTaskUpdateDTOCompletionStatus.Paid;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Satisfied) {
      return WellnessTaskUpdateDTOCompletionStatus.Customer_Satisfied;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Quote_Expired) {
      return WellnessTaskUpdateDTOCompletionStatus.Quote_Expired;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Quote_Rejected) {
      return WellnessTaskUpdateDTOCompletionStatus.Quote_Rejected
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Opened) {
      return WellnessTaskUpdateDTOCompletionStatus.Opened;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Sent) {
      return WellnessTaskUpdateDTOCompletionStatus.Sent;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.New) {
      return WellnessTaskUpdateDTOCompletionStatus.New;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Transformed_to_Quote) {
      return WellnessTaskUpdateDTOCompletionStatus.Transformed_to_Quote;
    }else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Pending) {
      return WellnessTaskUpdateDTOCompletionStatus.Pending;
    }
  }
}
