import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { WellnessTaskTreeType, SkillGenSvc, TreeTypeGenSvc, WellnessTask, WellnessTaskTemplateGenSvc, WellnessWorkOrder, WellnessTaskGenSvc, WorkOrderWellnessTask, PriorityLevelGenSvc, WellnessTaskSkill, WellnessTaskEquipment, QuoteWorkOrder, EquipmentTypeGenSvc, QuoteWorkOrderCompletionStatus, WellnessTaskCompletionStatus, WellnessWorkOrderCompletionStatus, WellnessTaskPaymentStatus, WellnessTaskGoBackEquipment, WellnessTaskUpdateDTO, WellnessTaskUpdateDTOManualPriorityShortcutType, WellnessTaskUpdateDTOCompletionStatus } from '../../services_autogenerated/generated_services';
import { MaskService } from 'src/app/services/mask.service';
import { MessageService } from 'primeng/api';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { BaseTaskCardMaintenanceComponent } from '../base-task-card-maintenance/base-task-card-maintenance.component';
import * as moment from 'moment';
import { PriorityAndDateSelectorComponent } from '../priority-and-date-selector/priority-and-date-selector.component';
var WellnessTaskMaintenanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WellnessTaskMaintenanceComponent, _super);
    function WellnessTaskMaintenanceComponent(skillService, equipmentTypeService, treeTypeService, priorityLevelService, messageService, maskService, authHelper, templateService, wellnessTaskService) {
        var _this = _super.call(this, skillService, equipmentTypeService, treeTypeService, priorityLevelService, messageService, maskService, authHelper) || this;
        _this.templateService = templateService;
        _this.wellnessTaskService = wellnessTaskService;
        _this.showRangeCalculationOption = false;
        _this.isInvoiced = false;
        _this.statuses = [WellnessTaskCompletionStatus.Completed];
        return _this;
    }
    WellnessTaskMaintenanceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currencyMask = this.maskService.currencyMaskNoDecimal;
        this.isQuoteTask = this.workOrder instanceof QuoteWorkOrder;
        if (!this.task) {
            var task = new WellnessTask();
            task.active = true;
            task.renewable = true;
            task.renewalAccepted = false;
            task.renewed = false;
            task.completionStatus = this.workOrder instanceof WellnessWorkOrder ?
                WellnessTaskCompletionStatus.Ready_to_Schedule :
                WellnessTaskCompletionStatus.New;
            task.fixedPrice = true;
            task.taskHourEntries = [];
            task.wellnessTaskChemicalCombos = [];
            task.wellnessTaskEquipment = [];
            task.wellnessTaskSkills = [];
            task.wellnessTaskTreeTypes = [];
            this.task = task;
            if (this.workOrder instanceof QuoteWorkOrder) {
                // defaults to the quote's priority type/dates
                this.task.hasCalculatedPriority = this.workOrder.hasCalculatedPriority;
                this.task.dueDateStart = this.workOrder.dueDateStart;
                this.task.dueDateEnd = this.workOrder.dueDateEnd;
                this.task.hardStartDate = this.workOrder.hardStartDate;
                this.task.hardEndDate = this.workOrder.hardEndDate;
                this.task.manualPriorityShortcutType = this.workOrder.manualPriorityShortcutType;
                this.task.manualRangeEnd = this.workOrder.manualRangeEnd;
                this.task.manualRangeStart = this.workOrder.manualRangeStart;
                this.task.manualPriorityLevel = this.workOrder.manualPriorityLevel;
                this.task.priorityLevelId = this.workOrder.priorityLevelId;
                this.task.priorityShortcutId = this.workOrder.priorityShortcutId;
                this.task.priorityTypeShortcut = this.workOrder.priorityTypeShortcut;
            }
            else if (this.workOrder.workOrderWellnessTasks.length > 0) {
                var firstTaskOnWO = this.workOrder.workOrderWellnessTasks[0].wellnessTask;
                this.task.hasCalculatedPriority = firstTaskOnWO.hasCalculatedPriority;
                this.task.dueDateStart = firstTaskOnWO.dueDateStart;
                this.task.dueDateEnd = firstTaskOnWO.dueDateEnd;
                this.task.hardStartDate = firstTaskOnWO.hardStartDate;
                this.task.hardEndDate = firstTaskOnWO.hardEndDate;
                this.task.manualPriorityShortcutType = firstTaskOnWO.manualPriorityShortcutType;
                this.task.manualRangeEnd = firstTaskOnWO.manualRangeEnd;
                this.task.manualRangeStart = firstTaskOnWO.manualRangeStart;
                this.task.manualPriorityLevel = firstTaskOnWO.manualPriorityLevel;
                this.task.priorityLevelId = firstTaskOnWO.priorityLevelId;
                this.task.priorityShortcutId = firstTaskOnWO.priorityShortcutId;
                this.task.priorityTypeShortcut = firstTaskOnWO.priorityTypeShortcut;
            }
        }
        else {
            this.copyTask();
            this.isInvoiced = this.task.paymentStatus === WellnessTaskPaymentStatus.Invoiced || this.task.paymentStatus === WellnessTaskPaymentStatus.Paid;
        }
        this.setUploadId();
        this.dependencyOptions = this.workOrder.workOrderWellnessTasks
            .map(function (wowt) { return wowt.wellnessTask; })
            .filter(function (wt) { return wt.active && wt !== _this.task; });
        var claims = this.authHelper.getDecodedAccessToken().claimNames;
        this.limitEditing = claims.includes('Edit Assigned Tasks');
        this.isEditable = (this.task.completionStatus !== WellnessTaskCompletionStatus.Completed
            && this.task.completionStatus !== WellnessTaskCompletionStatus.Go_Back
            && this.task.completionStatus !== WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up)
            && ((this.task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed
                && this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Quote_Rejected
                && this.workOrder instanceof WellnessWorkOrder ?
                this.workOrder.completionStatus !== WellnessWorkOrderCompletionStatus.Completed :
                this.workOrder.completionStatus !== QuoteWorkOrderCompletionStatus.Work_Orders_Created)
                || claims.includes('Full Control'));
        this.canEditUploads = claims.includes('View/Edit Work Order');
        this.canComplete = this.task.id && this.task.completionStatus !== WellnessTaskCompletionStatus.Completed
            && this.task.scheduleDateFrom !== undefined
            && this.isEditable && claims.includes('Completing a Task')
            && this.task.completionStatus !== WellnessTaskCompletionStatus.Unable_to_be_Completed;
        this.isIncompleteGoBack = this.task.completionStatus !== WellnessTaskCompletionStatus.Completed && this.task.isGoBack;
        if (claims.includes('Can Initiate Go Back')) {
            this.statuses.push(WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up);
            this.canChangeStatus = this.task.completionStatus === WellnessTaskCompletionStatus.Completed
                || this.task.completionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                || this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back
                || this.isIncompleteGoBack;
        }
        if (claims.includes('Can Set Go Back')) {
            this.statuses.push(WellnessTaskCompletionStatus.Go_Back);
            this.canChangeStatus = this.task.completionStatus === WellnessTaskCompletionStatus.Completed
                || this.task.completionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up
                || this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back
                || this.isIncompleteGoBack;
        }
        this.templateService.getAllActive().subscribe(function (templates) {
            _this.templateOptions = templates;
        });
        this.skillService.getAll().subscribe(function (skills) {
            if (_this.task.wellnessTaskSkills) {
                _this.skillOptions = _this.task.wellnessTaskSkills;
            }
            else {
                _this.skillOptions = [];
                _this.task.wellnessTaskSkills = [];
            }
            var otherSkills = skills.filter(function (s) { return !_this.task.wellnessTaskSkills.some(function (wts) { return wts.skillId === s.id; }); });
            _this.skillOptions = _this.skillOptions.concat(otherSkills.map(function (skill) {
                return new WellnessTaskSkill({ skill: skill, skillId: skill.id, wellnessTaskId: _this.task.id });
            }));
        });
        this.equipmentTypeService.getActive().subscribe(function (equipment) {
            if (_this.task.wellnessTaskEquipment) {
                _this.equipmentOptions = _this.task.wellnessTaskEquipment;
            }
            else {
                _this.equipmentOptions = [];
                _this.task.wellnessTaskEquipment = [];
            }
            if (_this.task.wellnessTaskGoBackEquipment) {
                _this.goBackEquipmentOptions = _this.task.wellnessTaskGoBackEquipment;
            }
            else {
                _this.goBackEquipmentOptions = [];
                _this.task.wellnessTaskGoBackEquipment = [];
            }
            var otherEquipment = equipment.filter(function (e) {
                return !_this.task.wellnessTaskEquipment.some(function (wte) { return wte.equipmentTypeId === e.id; });
            });
            var otherGoBackEquipment = equipment.filter(function (e) { return !_this.task.wellnessTaskGoBackEquipment.some(function (wte) { return wte.goBackEquipmentId === e.id; }); });
            _this.equipmentOptions = _this.equipmentOptions.concat(otherEquipment.map(function (equip) {
                return new WellnessTaskEquipment({ equipmentType: equip, equipmentTypeId: equip.id, wellnessTaskId: _this.task.id
                });
            }));
            _this.goBackEquipmentOptions = _this.goBackEquipmentOptions.concat(otherGoBackEquipment.map(function (equip) {
                return new WellnessTaskGoBackEquipment({ equipmentType: equip, goBackEquipmentId: equip.id, wellnessTaskId: _this.task.id
                });
            }));
        });
        this.treeTypeService.getAll().subscribe(function (types) {
            if (_this.task.wellnessTaskTreeTypes) {
                _this.treeTypeOptions = _this.task.wellnessTaskTreeTypes; // start with existing types
            }
            else {
                _this.treeTypeOptions = [];
                _this.task.wellnessTaskTreeTypes = [];
            }
            // add other options from types they could pick from
            var otherTypes = types.filter(function (t) {
                return !_this.task.wellnessTaskTreeTypes.some(function (wttt) { return wttt.treeTypeId === t.id; });
            });
            _this.treeTypeOptions = _this.treeTypeOptions.concat(otherTypes.map(function (tt) {
                var newWTTT = new WellnessTaskTreeType();
                newWTTT.treeType = tt;
                newWTTT.treeTypeId = tt.id;
                newWTTT.wellnessTaskId = _this.task.id; // may be null if new equipment
                return newWTTT;
            }));
            _this.treeTypeOptions = _this.treeTypeOptions.map(function (tto) {
                return {
                    label: tto.treeType.genus ?
                        tto.treeType.commonName + " (" + tto.treeType.genus + " " + tto.treeType.species + ")" :
                        "" + tto.treeType.commonName,
                    value: tto
                };
            });
        });
    };
    WellnessTaskMaintenanceComponent.prototype.completeTask = function () {
        this.task.completionStatus = WellnessTaskCompletionStatus.Completed;
        if (!this.task.completedDate) {
            this.task.completedDate = new Date();
        }
    };
    WellnessTaskMaintenanceComponent.prototype.onSchedulableAfterCompletionOfIdChange = function () {
        if (!this.task.schedulableAfterCompletionOfId) {
            this.task.schedulableAfterCompletionOf = null;
        }
    };
    WellnessTaskMaintenanceComponent.prototype.saveTask = function (ngForm) {
        var _this = this;
        Object.keys(ngForm.controls).forEach(function (key) {
            ngForm.controls[key].markAsTouched();
            ngForm.controls[key].markAsDirty();
        });
        if (!ngForm.invalid) {
            if (this.task.id) {
                var dto = new WellnessTaskUpdateDTO({
                    id: this.task.id,
                    name: this.task.name,
                    price: this.task.price,
                    hours: this.task.hours,
                    description: this.task.description,
                    treeTypeIds: this.task.wellnessTaskTreeTypes.map(function (wttt) { return wttt.treeTypeId; }),
                    location: this.task.location,
                    skillIds: this.task.wellnessTaskSkills.map(function (wts) { return wts.skillId; }),
                    equipmentTypeIds: this.task.wellnessTaskEquipment.map(function (wte) { return wte.equipmentTypeId; }),
                    schedulableAfterCompletionOfId: this.task.schedulableAfterCompletionOfId,
                    completionStatus: this.dumbEnumConversion(this.task.completionStatus),
                    customerIssue: this.task.customerIssue,
                    goBackHoursEstimate: this.task.goBackHoursEstimate,
                    goBackEquipmentTypeIds: this.task.wellnessTaskGoBackEquipment.map(function (wtgbe) { return wtgbe.goBackEquipmentId; }),
                    // priority stuff
                    priorityLevelId: this.task.manualPriorityLevel ? this.task.manualPriorityLevel.id : null,
                    hasCalculatedPriority: this.task.hasCalculatedPriority,
                    priorityShortcutId: this.task.priorityShortcutId,
                    dueDateStart: this.task.dueDateStart,
                    dueDateEnd: this.task.dueDateEnd,
                    hardStartDate: this.task.hardStartDate,
                    hardEndDate: this.task.hardEndDate,
                    manualPriorityShortcutType: WellnessTaskUpdateDTOManualPriorityShortcutType[this.task.manualPriorityShortcutType],
                    manualRangeStart: this.task.manualRangeStart,
                    manualRangeEnd: this.task.manualRangeEnd,
                    // wellness task only updates
                    renewable: this.task.renewable,
                    renewalAccepted: this.task.renewalAccepted,
                    renewed: this.task.renewed,
                    growingDegreeDay: this.task.growingDegreeDay,
                });
                this.isSaving = true;
                this.wellnessTaskService.updateWithDTO(dto).subscribe(function (wellnessTask) {
                    if (_this.task.completionStatus === WellnessTaskCompletionStatus.Go_Back) {
                        _this.task.hasCalculatedPriority = false;
                    }
                    _this.saveBlobs(wellnessTask);
                    _this.task = wellnessTask;
                }, function (error) {
                    _this.isSaving = false;
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Could not save the task, please ensure all the fields are valid and retry.'
                    });
                });
            }
            else {
                this.isSaving = true;
                // Save the wellness task
                this.wellnessTaskService.add(this.task).subscribe(function (wellnessTask) {
                    _this.task = wellnessTask;
                    var woWellnessTask = new WorkOrderWellnessTask();
                    woWellnessTask.wellnessTask = wellnessTask;
                    woWellnessTask.wellnessTaskId = wellnessTask.id;
                    if (_this.workOrder instanceof WellnessWorkOrder) {
                        woWellnessTask.wellnessWorkOrderId = _this.workOrder.id;
                        if (_this.workOrder.completionStatus === WellnessWorkOrderCompletionStatus.Completed) {
                            // We are adding a task to completed WO (it's probably a go back) so change the WO's completion status
                            _this.workOrder.completionStatus = WellnessWorkOrderCompletionStatus.Ready_to_Schedule;
                        }
                        if (_this.task.priorityShortcutId) {
                            var backlog = _this.prioritySelector.priorityTypeOptions.find(function (p) { return p.id === _this.task.priorityShortcutId; });
                            if (backlog && backlog.weeksOutStart !== undefined) {
                                var dueDateStart = new Date();
                                var dueDateEnd = new Date();
                                if (_this.workOrder.workOrderWellnessTasks.length > 0 && _this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTask.priorityShortcutId === 3; })) {
                                    dueDateStart = _this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTask.priorityShortcutId === 3; }).wellnessTask.dueDateStart;
                                    dueDateEnd = _this.workOrder.workOrderWellnessTasks.find(function (wowt) { return wowt.wellnessTask.priorityShortcutId === 3; }).wellnessTask.dueDateEnd;
                                }
                                else if (backlog) {
                                    var daysOutStart = (backlog.weeksOutStart !== undefined ? backlog.weeksOutStart : 4) * 7;
                                    var daysOutEnd = (backlog.weeksOutEnd !== undefined ? backlog.weeksOutEnd : 5) * 7;
                                    dueDateStart = moment(dueDateStart).add(daysOutStart, 'd').toDate();
                                    dueDateEnd = moment(dueDateEnd).add(daysOutEnd, 'd').toDate();
                                }
                                _this.task.dueDateStart = dueDateStart;
                                _this.task.dueDateEnd = dueDateEnd;
                            }
                        }
                    }
                    else {
                        woWellnessTask.quoteWorkOrderId = _this.workOrder.id;
                    }
                    _this.workOrder.workOrderWellnessTasks.push(woWellnessTask);
                    _this.saveBlobs(wellnessTask);
                }, function (error) {
                    _this.isSaving = false;
                    _this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Could not add the task to the work order, please ensure all the fields are valid and retry.'
                    });
                });
            }
        }
    };
    WellnessTaskMaintenanceComponent.prototype.deleteButtonPressed = function () {
        // if (confirm('Are you really sure you want to delete this task? This operation cannot be undone.')) {
        //   const deleteIndex = this.workOrder.workOrderWellnessTasks.findIndex(wt => wt.wellnessTask.id === this.task.id);
        //   const deleted = this.workOrder.workOrderWellnessTasks[deleteIndex];
        //   if (deleted.id) {
        //     this.wellnessTaskService.delete(this.task.id).subscribe(() => {
        //       this.workOrder.workOrderWellnessTasks.splice(deleteIndex, 1);
        //       this.wellnessWorkOrderMaintenanceService.removeWellnessTaskDependentReferences(this.workOrder, deleted);
        //     });
        //   } else {
        //     this.workOrder.workOrderWellnessTasks.splice(deleteIndex, 1);
        //     this.wellnessWorkOrderMaintenanceService.removeWellnessTaskDependentReferences(this.workOrder, deleted);
        //   }
        // }
        this.delete.emit(this.task);
    };
    WellnessTaskMaintenanceComponent.prototype.dumbEnumConversion = function (wellnessTaskCompletionStatus) {
        // most common ones on top
        if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Unsatisfied__RM_Follow_Up) {
            return WellnessTaskUpdateDTOCompletionStatus.Customer_Unsatisfied__RM_Follow_Up;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Go_Back) {
            return WellnessTaskUpdateDTOCompletionStatus.Go_Back;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Completed) {
            return WellnessTaskUpdateDTOCompletionStatus.Completed;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Created) {
            return WellnessTaskUpdateDTOCompletionStatus.Created;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Ready_to_Schedule) {
            return WellnessTaskUpdateDTOCompletionStatus.Ready_to_Schedule;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Not_Notified) {
            return WellnessTaskUpdateDTOCompletionStatus.Customer_Not_Notified;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Notified) {
            return WellnessTaskUpdateDTOCompletionStatus.Customer_Notified;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Partially_Complete) {
            return WellnessTaskUpdateDTOCompletionStatus.Partially_Complete;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Credit_Card_Needed) {
            return WellnessTaskUpdateDTOCompletionStatus.Credit_Card_Needed;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Work_Orders_Created) {
            return WellnessTaskUpdateDTOCompletionStatus.Work_Orders_Created;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Unable_to_be_Completed) {
            return WellnessTaskUpdateDTOCompletionStatus.Unable_to_be_Completed;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Paid) {
            return WellnessTaskUpdateDTOCompletionStatus.Paid;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Customer_Satisfied) {
            return WellnessTaskUpdateDTOCompletionStatus.Customer_Satisfied;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Quote_Expired) {
            return WellnessTaskUpdateDTOCompletionStatus.Quote_Expired;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Quote_Rejected) {
            return WellnessTaskUpdateDTOCompletionStatus.Quote_Rejected;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Opened) {
            return WellnessTaskUpdateDTOCompletionStatus.Opened;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Sent) {
            return WellnessTaskUpdateDTOCompletionStatus.Sent;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.New) {
            return WellnessTaskUpdateDTOCompletionStatus.New;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Transformed_to_Quote) {
            return WellnessTaskUpdateDTOCompletionStatus.Transformed_to_Quote;
        }
        else if (wellnessTaskCompletionStatus === WellnessTaskCompletionStatus.Pending) {
            return WellnessTaskUpdateDTOCompletionStatus.Pending;
        }
    };
    return WellnessTaskMaintenanceComponent;
}(BaseTaskCardMaintenanceComponent));
export { WellnessTaskMaintenanceComponent };
