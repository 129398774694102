import { inject, Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthHelperService } from './services/auth-helper.service';
import { catchError, timeout } from 'rxjs/operators';
import { LogGenSvc, LogViewModel, LogViewModelLevel } from './services_autogenerated/generated_services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authHelperService: AuthHelperService;

    constructor(private injector: Injector) {
       }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {this.authHelperService = this.injector.get(AuthHelperService); // get it here within intercept
        const token = localStorage.getItem(this.authHelperService.authToken);
        // specifically do not want to add auth token to requests going from UI directly to Azure blob service
        const isAngularBlobRequest = request.url.startsWith('https://russelltreeappstorage.blob.core.windows.net');
        if (token && !isAngularBlobRequest) {
            const authRequest = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`)
            });
            return next.handle(authRequest);
        } else {
            return next.handle(request);
        }
    }
}

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

    private authHelperService: AuthHelperService;

    constructor(private injector: Injector) {
       }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authHelperService = this.injector.get(AuthHelperService);

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse){
                    let message = `Message: ${error.message ? error.message : error.toString()}`;
                    
                    if (error.status)
                        message += ` \n   -- Status: ${error.status}`;
                    if (error.statusText)
                        message += ` \n   -- Status: ${error.statusText}`;
                    if (error.url)
                        message += ` \n   -- URL: ${error.url}`;
                
                    const errorModel = new LogViewModel({
                    level: LogViewModelLevel.Error,
                    message: message,
                    entryDate: new Date()
                    });
                
                    this.authHelperService.logging(errorModel).subscribe();
                }
                return throwError(error)
            }),
            // timeout(10000)
        );
    }
}
