<div class="ui-g">
    <div class="ui-g-12"
        style="margin-top: 11px;" *ngIf="isEditable && !task.id">
        <ng-select 
            class="custom"
            required
            [items]="templateOptions" 
            placeholder="Select a Task Template" 
            [selectOnTab]="true"
            name="task" 
            bindLabel="name"
            (change)="onTemplateSelect($event)"
            *ngIf="isEditable && !task.id">
        </ng-select>
    </div>
</div>
<form class="ui-g" id="wellnessTaskForm" #wellnessTaskForm="ngForm">
    <div class="ui-g-12" *ngIf="canChangeStatus">
        <label>Status</label>
        <ng-select
          class="custom"
          [items]="statuses"
          name="status"
          [clearable]="false"
          [(ngModel)]="task.completionStatus"
        ></ng-select> 
    </div>
    <div class="ui-g-12" *ngIf="canChangeStatus && task.completionStatus != 'Completed'">
        <label>Customer's Issue</label>
        <textarea
          pInputTextarea
          [(ngModel)]="task.customerIssue"
          [rows]="3"
          name="customerIssue"
          style="width: 100%;"
          required
          placeholder=""
        ></textarea>
    </div>
    <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
        <label>Hours (For Go Back)</label>
        <input pInputText
          class="inputTextBox"
          name="goBackHours"
          required
          [(ngModel)]="task.goBackHoursEstimate"
          *ngIf="task" />
    </div>
    <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
        <label>Required Equipment (For Go Back)</label>
        <p-multiSelect [options]="goBackEquipmentOptions"
          [(ngModel)]="task.wellnessTaskGoBackEquipment"
          [panelStyle]="{minWidth:'12em'}"
          name="goBackEquipment"
          optionLabel="equipmentType.type"
          required
          *ngIf="task">
          <ng-template let-value
            pTemplate="selectedItems">
            <div *ngFor="let val of value"
              class="ui-multiselected-item-token ui-corner-all">
              <span>{{val.equipmentType.type}}</span>
            </div>
            <span *ngIf="!value || value.length === 0"
              class="ui-multiselected-empty-token ui-corner-all">Choose</span>
          </ng-template>
          <ng-template let-equipment
            pTemplate="item">
            {{equipment.label}}
          </ng-template>
        </p-multiSelect>
    </div>
    <div class="ui-g-12" *ngIf="canChangeStatus && (task.completionStatus == 'Go Back' || isIncompleteGoBack)">
        <label>Uploads (For Go Back)</label>
        <app-blob-manager
          [containerName]="'wellness-task-go-back'" 
          [parentRecordId]="uploadId" 
          [uploadLabel]="'Document/Photo'" 
          [viewOnly]="!canEditUploads">
        </app-blob-manager>
    </div>
    <div class="ui-g-12">
        <p-card>
            <div class="ui-g">
                <div class="ui-g-12 ui-lg-6 ui-md-9">
                    <h6>Task Name</h6>
                    <input pInputText
                        name="name"
                        class="inputTextBox"
                        [(ngModel)]="task.name"
                        *ngIf="task"
                        [disabled]="!isEditable || limitEditing"
                        required />
                </div>
                <div class="ui-g-12 ui-lg-6 ui-md-6">
                    <h6>Growing Degree Day</h6>
                    <input pInputText
                        class="inputTextBox"
                        name="gdd"
                        type="number"
                        min="0"
                        oninput="validity.valid||(value='');"
                        [(ngModel)]="task.growingDegreeDay"
                        [disabled]="!isEditable || limitEditing"
                        *ngIf="task" />
                </div>
                <!-- <div class="ui-g-12 ui-lg-2 ui-md-3">
                    <h6>Status</h6>
                    <input pInputText
                        disabled
                        class="inputTextBox"
                        name="stage"
                        [ngModel]="task.completionStatus"
                        *ngIf="task" />
                </div> -->
                <!--
                <div class="ui-g-12 ui-lg-4 ui-md-3">
                    <h6>Cost</h6>
                    <input pInputText
                        class="inputTextBox"
                        name="cost"
                        required
                        numeric
                        [textMask]="{mask: currencyMask}"
                        [ngModel]="task.cost"
                        *ngIf="task"
                        [disabled]="!isEditable"
                        (ngModelChange)="task.cost=$event" />
                </div>
                -->
                <div class="ui-g-12 ui-lg-6 ui-md-3">
                    <h6>Price</h6>
                    <input pInputText
                        class="inputTextBox"
                        name="price"
                        required
                        numeric
                        [textMask]="{mask: currencyMask}"
                        [ngModel]="task.price"
                        *ngIf="task"
                        [disabled]="!isEditable || isInvoiced"
                        (ngModelChange)="task.price=$event" />
                </div>
                <div class="ui-g-12 ui-lg-6 ui-md-3">
                    <h6>Hours Estimate</h6>
                    <input pInputText
                        class="inputTextBox"
                        name="hours"
                        required
                        [(ngModel)]="task.hours"
                        *ngIf="task"
                        [disabled]="!isEditable || limitEditing" />
                </div>
                <div class="ui-g-12 columnPadding topMargin">
                    <h6>Description</h6>
                    <textarea pInputTextarea
                        name="description"
                        required
                        class="form-control"
                        [(ngModel)]="task.description"
                        *ngIf="task"
                        [rows]="8"
                        style="width: 100%;"
                        [disabled]="!isEditable">
                    </textarea>
                </div>
            </div>
        </p-card>
    </div>
    <div class="ui-g-12 multiSelect">
        <h6>Tree Type(s)</h6>
        <p-multiSelect [options]="treeTypeOptions"
            [(ngModel)]="task.wellnessTaskTreeTypes"
            [panelStyle]="{minWidth:'12em'}"
            [disabled]="!isEditable || limitEditing"
            [showToggleAll]="false"
            name="treeTypes"
            required
            *ngIf="task">
            <ng-template let-value
                pTemplate="selectedItems">
                <div *ngFor="let val of value"
                    class="ui-multiselected-item-token ui-corner-all">
                    <span *ngIf="val.treeType">{{val.treeType.commonName}}</span>
                    <span *ngIf="!val.treeType">Unknown</span>
                </div>
                <span *ngIf="!value || value.length === 0"
                    class="ui-multiselected-empty-token ui-corner-all">Choose</span>
            </ng-template>
            <ng-template let-wttt pTemplate="item">
                {{wttt.label}}
            </ng-template>
        </p-multiSelect>
    </div>
    <div class="ui-g-12">
        <h6>Location of Tree(s)</h6>
        <textarea pInputTextarea
            [(ngModel)]="task.location"
            [disabled]="!isEditable || limitEditing"
            class="form-control"
            [rows]="2"
            name="location"
            style="width: 100%;"
            required
            *ngIf="task">
        </textarea>
    </div>
    <div class="ui-g-12 multiSelect">
        <h6>Required Skill(s)/Certification(s)</h6>
        <p-multiSelect [options]="skillOptions"
            [(ngModel)]="task.wellnessTaskSkills"
            [disabled]="!isEditable || limitEditing"
            name="skills"
            [panelStyle]="{minWidth:'12em'}"
            optionLabel="skill.name"
            *ngIf="task">
            <ng-template let-value
                pTemplate="selectedItems">
                <div *ngFor="let val of value"
                    class="ui-multiselected-item-token ui-corner-all">
                    <span>{{val.skill.name}}</span>
                </div>
                <span *ngIf="!value || value.length === 0"
                    class="ui-multiselected-empty-token ui-corner-all">Choose</span>
            </ng-template>
            <ng-template let-skill
                pTemplate="item">
                {{skill.label}}
            </ng-template>
        </p-multiSelect>
    </div>
    <div class="ui-g-12 multiSelect">
        <h6>Required Equipment Type(s)</h6>
        <p-multiSelect [options]="equipmentOptions"
            [(ngModel)]="task.wellnessTaskEquipment"
            [panelStyle]="{minWidth:'12em'}"
            [disabled]="!isEditable || limitEditing"
            name="equipment"
            optionLabel="equipmentType.type"
            *ngIf="task">
            <ng-template let-value
                pTemplate="selectedItems">
                <div *ngFor="let val of value"
                    class="ui-multiselected-item-token ui-corner-all">
                    <span>{{val.equipmentType.type}}</span>
                </div>
                <span *ngIf="!value || value.length === 0"
                    class="ui-multiselected-empty-token ui-corner-all">Choose</span>
            </ng-template>
            <ng-template let-equipment
                pTemplate="item">
                {{equipment.label}}
            </ng-template>
        </p-multiSelect>
    </div>
    <div class="ui-g-12">
        <h6>Schedulable After The Completion Of</h6>
        <ng-select 
            class="custom"
            [items]="dependencyOptions"
            [disabled]="!isEditable || limitEditing"
            [selectOnTab]="true"
            placeholder="Does this task require another task to be completed first?" 
            name="SchedulableAfterCompletionOf" 
            bindLabel="name"
            bindValue="id"
            (change)="onSchedulableAfterCompletionOfIdChange()"
            [(ngModel)]="task.schedulableAfterCompletionOfId"
            *ngIf="task">
        </ng-select>
    </div>
    <div class="ui-g-12" *ngIf="isQuoteTask">
        <h6>Offer Yearly Auto Renew Service</h6>
        <p-radioButton
        name="renewable"
        [value]="true"
        [label]="'Yes'"
        [(ngModel)]="task.renewable"
        [disabled]="!isEditable || limitEditing"
        required
        *ngIf="task">
      </p-radioButton>&nbsp;&nbsp;
      <p-radioButton
        name="notRenewable"
        [value]="false"
        [label]="'No'"
        [(ngModel)]="task.renewable"
        [disabled]="!isEditable || limitEditing"
        required
        *ngIf="task">
      </p-radioButton>
    </div>
    <div class="ui-g-12" *ngIf="task.renewable && !isQuoteTask">
        <h6>Service Will Renew</h6>
        <p-radioButton
            name="willRenew"
            [value]="true"
            [label]="'Yes'"
            [(ngModel)]="task.renewalAccepted"
            [disabled]="(!isEditable || isSaving || limitEditing) && !canChangeStatus && !task.renewed"
            required
            *ngIf="task">
        </p-radioButton>&nbsp;&nbsp;
        <p-radioButton
            name="willNotRenew"
            [value]="false"
            [label]="'No'"
            [(ngModel)]="task.renewalAccepted"
            [disabled]="(!isEditable || isSaving || limitEditing) && !canChangeStatus && !task.renewed"
            required
            *ngIf="task">
        </p-radioButton>
    </div>
    <div class="ui-g-12">
        <h6>Priority</h6>
        <app-priority-and-date-selector
            #prioritySelector
            [(taskOrQuote)]="task" 
            [isEditable]="isEditable && !limitEditing"
            [showRangeCalculationOption]="showRangeCalculationOption">
        </app-priority-and-date-selector>
    </div>
    <div class="ui-g-12">
        <h6>Uploads:</h6>
        <app-blob-manager
            [containerName]="'wellness-task'" 
            [parentRecordId]="uploadId" 
            [uploadLabel]="'Document/Photo'" 
            [viewOnly]="!canEditUploads || limitEditing">
        </app-blob-manager>
    </div>
    <div class="ui-g-12 columnPadding">
        <div *ngIf="wellnessTaskForm.invalid && wellnessTaskForm.submitted" class="alert alert-danger" role="alert">
          There are errors on this page. Please correct the errors and resubmit.
        </div>
    </div>
    <div class="ui-g-12">
        <div class="ui-g">
            <div class="ui-g-12 columnPadding">
                <button pButton
                    type="submit"
                    class="ui-button-raised ui-button-success buttonStyleRight"
                    (click)="saveTask(wellnessTaskForm)"
                    [disabled]="(!isEditable || isSaving) && !canChangeStatus"
                    label=" Save">
                </button>
                <button pButton
                    type="button"
                    class="ui-button-raised ui-button-warning buttonStyleRight"
                    (click)="cancelButtonPressed()"
                    label="Cancel">
                </button>
                <button pButton
                    type="button"
                    class="ui-button-raised ui-button-danger buttonStyleRight"
                    (click)="deleteButtonPressed()"
                    label="Delete"
                    [disabled]="isInvoiced"
                    *ngIf="isEditable && task.id && !limitEditing">
                </button>
                <!--                 
                <button pButton
                    type="button"
                    class="ui-button-raised buttonStyle"
                    (click)="completeTask()"
                    label="Complete"
                    *ngIf="canComplete">
                </button> -->
            </div>
        </div>
    </div>
</form>