import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import { LogGenSvc, LogViewModel, LogViewModelLevel } from '../services_autogenerated/generated_services';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector,
    private zone: NgZone) {
    super();
  }

handleError(error) {
  const loggingService = this.injector.get(LogGenSvc);
  const router = this.injector.get(Router);
  console.log(error instanceof HttpErrorResponse);
  let message = `Message: ${error.message ? error.message : error.toString()}`;

  if (error.response) {
    message +=  ` \n   -- Response: ${error.response}`;
  }

  if (error.status)
    message += ` \n   -- Status: ${error.status}`;

  if (router.url)
    message += ` \n   -- Page: ${router.url}`;

  if (error.stack)
    message += ` \n   -- Stack: ${error.stack}`;

  const errorModel = new LogViewModel({
    level: LogViewModelLevel.Error,
    message: message,
    entryDate: new Date()
  });

  loggingService.add(errorModel).subscribe();
    if (error.status && error.status === 401) {
      this.zone.run(() => router.navigate(['/login']));
    }
    super.handleError(error);
  }
}
