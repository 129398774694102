import { Component, OnInit, Input } from '@angular/core';
import { CustomerGenSvc, AllWorkOrders, Customer, AllWorkOrdersWithBilling, WellnessWorkOrder, WorkWorkOrder, WorkOrderBilling, WellnessWorkOrderCompletionStatus, WorkWorkOrderCompletionStatus, QuoteRequest, WorkOrderInfoPaginatedRequest, AllQuoteRequests} from '../../../services_autogenerated/generated_services';
import { WorkOrderHelperService } from '../../../services/work-order-helper.service';

@Component({
  selector: 'app-customer-main-tab',
  templateUrl: './customer-main-tab.component.html',
  styleUrls: ['./customer-main-tab.component.css']
})
export class CustomerMainTabComponent implements OnInit {
  @Input() customer: Customer;

  workOrderHistoryInfo: AllWorkOrdersWithBilling;
  
  WorkWorkOrderCompletionStatus = WorkWorkOrderCompletionStatus;
  WellnessWorkOrderCompletionStatus = WellnessWorkOrderCompletionStatus;

  defaultTake: number = 10;

  quoteRequestsLoading: boolean;
  quoteRequestInfo: AllQuoteRequests;
  quoteRequestSkip: number = 0;
  quoteRequestRequest: WorkOrderInfoPaginatedRequest = new WorkOrderInfoPaginatedRequest();
  
  currentQuoteLoading: boolean;
  currentQuoteInfo: AllWorkOrders;
  currentQuoteSkip: number = 0;
  currentQuoteRequest: WorkOrderInfoPaginatedRequest = new WorkOrderInfoPaginatedRequest();

  quoteHistoryLoading: boolean;
  quoteHistoryInfo: AllWorkOrdersWithBilling;
  quoteHistorySkip: number = 0;
  quoteHistoryRequest: WorkOrderInfoPaginatedRequest = new WorkOrderInfoPaginatedRequest();
  
  currentWorkOrderLoading: boolean;
  currentWorkOrderInfo: AllWorkOrders;
  currentWorkOrderSkip: number = 0;
  currentWorkOrderRequest: WorkOrderInfoPaginatedRequest = new WorkOrderInfoPaginatedRequest();

  currentWorkOrders: (WellnessWorkOrder | WorkWorkOrder)[];
  
  historicalWorkOrderLoading: boolean;
  historicalWorkOrderInfo: AllWorkOrders;
  historicalWorkOrderSkip: number = 0;
  historicalWorkOrderRequest: WorkOrderInfoPaginatedRequest = new WorkOrderInfoPaginatedRequest();

  historicalWorkOrders: (WellnessWorkOrder | WorkWorkOrder)[];

  constructor(private customerService: CustomerGenSvc,
    private woHelper: WorkOrderHelperService) { }

  ngOnInit() {
    this.loadMoreCurrentQuote();
    this.loadMoreCurrentWorkOrders();
    this.loadMoreUnfinalizedQuoteRequests();
    this.loadMoreQuoteHistory();
    this.loadMoreHistoricalWorkOrders();
  }

  getWorkOrderBillingByWorkOrder(workOrder: WellnessWorkOrder | WorkWorkOrder): WorkOrderBilling {
    let woBilling = null;
    if (workOrder.constructor == WellnessWorkOrder) {
      woBilling = this.workOrderHistoryInfo.workOrderBilling.find(billing => +billing.workOrderId == workOrder.id && billing.workOrderType == 'Wellness');
      // TODO: type enum
    } else if (workOrder.constructor == WorkWorkOrder) {
      woBilling = this.workOrderHistoryInfo.workOrderBilling.find(billing => +billing.workOrderId == workOrder.id && billing.workOrderType == 'Work');
    }
    return woBilling;
  }

  loadMoreUnfinalizedQuoteRequests() {
    this.quoteRequestsLoading = true;
    this.quoteRequestRequest.customerId = this.customer.id;
    this.quoteRequestRequest.skip = this.quoteRequestSkip;
    this.quoteRequestRequest.take = this.defaultTake;

    this.customerService.getUnfinalizedQuoteRequests(this.quoteRequestRequest).subscribe(requests => {
      if (!this.quoteRequestInfo) {
        this.quoteRequestInfo = requests;
      } else {
        this.quoteRequestInfo.quoteRequests = this.quoteRequestInfo.quoteRequests.concat(requests.quoteRequests);
      }

      this.quoteRequestSkip += this.defaultTake;
      this.quoteRequestsLoading = false;
    });
  }

  loadMoreCurrentQuote() {
    this.currentQuoteLoading = true;
    this.currentQuoteRequest.customerId = this.customer.id;
    this.currentQuoteRequest.skip = this.quoteHistorySkip;
    this.currentQuoteRequest.take = this.defaultTake;

    this.customerService.getCurrentQuotes(this.currentQuoteRequest).subscribe(current => {
      if (!this.currentQuoteInfo) {
        this.currentQuoteInfo = current;
      } else {
        this.currentQuoteInfo.quoteWorkOrders = this.currentQuoteInfo.quoteWorkOrders.concat(current.quoteWorkOrders)
      }

      this.currentQuoteSkip += this.defaultTake;
      this.currentQuoteLoading = false;
    });

  }

  loadMoreQuoteHistory() {
    this.quoteHistoryLoading = true;
    this.quoteHistoryRequest.customerId = this.customer.id;
    this.quoteHistoryRequest.skip = this.quoteHistorySkip;
    this.quoteHistoryRequest.take = this.defaultTake;

    this.customerService.getQuoteHistoryInfo(this.quoteHistoryRequest).subscribe(history => {
      if (!this.quoteHistoryInfo) {
        this.quoteHistoryInfo = history;
      } else {
        this.quoteHistoryInfo.quoteWorkOrders = this.quoteHistoryInfo.quoteWorkOrders.concat(history.quoteWorkOrders);
      }

      this.quoteHistorySkip += this.defaultTake;
      this.quoteHistoryLoading = false;
    });
  }

  loadMoreCurrentWorkOrders() {
    this.currentWorkOrderLoading = true;
    this.currentWorkOrderRequest.customerId = this.customer.id;
    this.currentWorkOrderRequest.skip = this.currentWorkOrderSkip;
    this.currentWorkOrderRequest.take = this.defaultTake;

    this.customerService.getCurrentWorkOrders(this.currentWorkOrderRequest).subscribe(current => {
      current.wellnessWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      current.workWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      if (!this.currentWorkOrderInfo) {
        this.currentWorkOrderInfo = current;
        this.currentWorkOrders = [].concat(current.wellnessWorkOrders).concat(current.workWorkOrders).sort((a, b) => b.quoteId - a.quoteId );
      } else {
        this.currentWorkOrders = this.currentWorkOrders.concat(current.wellnessWorkOrders).concat(current.workWorkOrders).sort((a, b) => b.quoteId - a.quoteId );
      }

      this.currentWorkOrderSkip += this.defaultTake;
      this.currentWorkOrderLoading = false;
    });
  }

  loadMoreHistoricalWorkOrders() {
    this.historicalWorkOrderLoading = true;
    this.historicalWorkOrderRequest.customerId = this.customer.id;
    this.historicalWorkOrderRequest.skip = this.historicalWorkOrderSkip;
    this.historicalWorkOrderRequest.take = this.defaultTake;

    this.customerService.getWorkOrderHistoryInfo(this.historicalWorkOrderRequest).subscribe(history => {
      history.wellnessWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      history.workWorkOrders.forEach(wo => {
        wo.priceSummary = this.woHelper.subTotal(wo);
      });

      if (!this.workOrderHistoryInfo) {
        this.workOrderHistoryInfo = history;
        this.historicalWorkOrders = [].concat(history.wellnessWorkOrders).concat(history.workWorkOrders).sort((a, b) => b.quoteId - a.quoteId );
      } else {
        this.historicalWorkOrders = this.historicalWorkOrders.concat(history.wellnessWorkOrders).concat(history.workWorkOrders).sort((a, b) => b.quoteId - a.quoteId );
      }

      this.historicalWorkOrderSkip += this.defaultTake;
      this.historicalWorkOrderLoading = false;
    });
  }
}
