import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DecodedJwt } from '../models/decodedJwt';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { API_BASE_URL, LogViewModel, SwaggerException } from '../services_autogenerated/generated_services';

import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthHelperService {
    isLoggedInSubject = new Subject<boolean>();
    readonly authToken = 'russell_tree_auth_token';
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(
        private http: HttpClient,
        private router: Router,
        protected localStorage: LocalStorage,
        private route: ActivatedRoute,
        private appConfigService: AppConfigService
    ) { }

    logging(logData: LogViewModel) {
        
        let url_ = this.appConfigService.appConfig.apiUrl + "/api/v1/log";
        
        const content_ = JSON.stringify(logData);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json", 
                "Accept": "application/json"
            })
        };
        
        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
                return this.processAdd(response_);
            })).pipe(_observableCatch((response_: any) => {
                if (response_ instanceof HttpResponseBase) {
                    try {
                        return this.processAdd(<any>response_);
                    } catch (e) {
                        return <Observable<any>><any>_observableThrow(e);
                    }
                } else
                    return <Observable<any>><any>_observableThrow(response_);
            }));
        }

        protected processAdd(response: HttpResponseBase): Observable<any> {
            const status = response.status;
            const responseBlob = 
                response instanceof HttpResponse ? response.body : 
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;
    
            let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }};
            if (status === 200) {
                return this.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = resultData200 !== undefined ? resultData200 : <any>null;
                return _observableOf(result200);
                }));
            } else if (status !== 200 && status !== 204) {
                return this.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
                }));
            }
            return _observableOf<any>(<any>null);
        }

        protected throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
            if(result !== null && result !== undefined)
                return _observableThrow(result);
            else
                return _observableThrow(new SwaggerException(message, status, response, headers, null));
        }

        protected blobToText(blob: any): Observable<string> {
            return new Observable<string>((observer: any) => {
                if (!blob) {
                    observer.next("");
                    observer.complete();
                } else {
                    let reader = new FileReader(); 
                    reader.onload = event => { 
                        observer.next((<any>event.target).result);
                        observer.complete();
                    };
                    reader.readAsText(blob); 
                }
            });
        }
    

    logOut() {
        localStorage.removeItem(this.authToken);
        this.router.navigate(['/login']);
        this.isLoggedInSubject.next(false);
    }

    getDecodedAccessToken(token?: string): DecodedJwt {
        try {
            if (!token) { token = localStorage.getItem(this.authToken); }
            const decodedToken = jwt_decode(token);
            return new DecodedJwt(decodedToken);
        } catch (Error) {
            return null;
        }
    }

    isLoggedIn(): boolean {
        const token = this.getDecodedAccessToken();
        let notExpired = token && new Date(token.exp * 1000) > new Date(); // change to ms then date
        if (!notExpired) {  // makes notExpired non null
            notExpired = false;
        }
        this.isLoggedInSubject.next(notExpired);
        return notExpired;
    }

    redirectToHome() {
        const token = this.getDecodedAccessToken();
        if (this.requiresPasswordReset(token)) {
            if (this.route.snapshot.queryParams.asAnonymous) {
                const extras: NavigationExtras = {
                    queryParams: { 'asAnonymous': this.route.snapshot.queryParams.asAnonymous }
                  };
                  this.router.navigate(['/passwordChange'], extras);
            } else {
                this.router.navigate(['/passwordChange']);
            }
        } else if (token.userType === 'customer') {
            this.router.navigate(['/customerLandingPage/' + token.id]);
        } else if (token.roles.includes('Arborist') || token.roles.includes('Foreman')) {
            this.router.navigate(['/scheduling/myschedule']);
        } else {
            this.router.navigate(['/welcome']);
        }
        this.isLoggedInSubject.next(true);
    }

    requiresPasswordReset(token: DecodedJwt) {
        if (token.requirePasswordReset === 'True') {
            return true;
        } else if (token.passwordIsExpired === 'True') {
            return true;
        } else {
            return false;
        }
    }

    requiresMultiFactorAuthSetup(token: DecodedJwt) {
        if (token.requireMultiFactorAuthSetup === 'True') {
            return true;
        } else {
            return false;
        }
    }

    requiresMultiFactorAuth(token: DecodedJwt) {
        if (token.requireMultiFactorAuth === 'True') {
            return true;
        } else {
            return false;
        }
    }

    hasClaim(claim: string) {
        const token = this.getDecodedAccessToken();
        if (Object.keys(token).indexOf(claim) >= 0) {
            return true;
        } else {
            return false;
        }
    }
}
