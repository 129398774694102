<div *ngIf="isLoading">
  <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>Loading...
</div>

<div *ngIf="errorMessage" ><p-message severity="error" [text]="errorMessage"></p-message></div>

<form id="workOrderForm" #workOrderForm="ngForm" *ngIf="workOrder && !isLoading">
  <div class="ui-g iPhoneFix ui-g-nopad">
    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
      <div class="ui-g ui-g-nopad">
        <div class="ui-g-12 ui-md-6 ui-lg-6 ui-g-nopad">
          <h4 style="display: inline-block; margin-right: 8px;">Quote Maintenance</h4><i class="fa fa-link" (click)="copyOneClickLink()" pTooltip="Click to copy 1-click quote link" *ngIf="workOrder.id"></i>
        </div>
      </div>
      <!-- Common top section includes basic WO information such as customer, address, representative, message to customer -->
      <app-work-order-maintenance-common-fields-top
        [(workOrder)]="workOrder"
        [isEditable]="isEditable"
        [isCustomer]="isCustomer">
      </app-work-order-maintenance-common-fields-top>
      <div style="margin-top: 8px;">
        <button 
          pButton 
          type="button" 
          class="ui-button-raised ui-button-warning" 
          label="Undo Customer Approval"
          [disabled]="disableReset"
          *ngIf="showReset"
          (click)="resetToEditable()">
        </button>
      </div>
      <div class="ui-g-6">
        <div class="bold">
          Regional Manager
        </div>
        <ng-select
          class="custom"
          required
          [searchable]="false"
          [selectOnTab]="true"
          [items]="employees"
          placeholder="Select a Regional Manager"
          name="rep"
          bindLabel="fullName"
          [(ngModel)]="workOrder.representative"
          (ngModelChange)="repChanged()"
          [disabled]="!isEditable"
          *ngIf="workOrder">
        </ng-select>
      </div>
      <div class="ui-g-6">
        <div class="bold">
          Payment Due
        </div>
        <ng-select
          class="custom"
          required
          [searchable]="false"
          [selectOnTab]="true"
          [items]="paymentDueOptions"
          placeholder="Select an Option"
          name="paymentDueOption"
          bindLabel="paymentDueName"
          [(ngModel)]="workOrder.paymentDueOption"
          [disabled]="!isEditable"
          *ngIf="workOrder">
        </ng-select>
      </div>
      <div class="ui-g-12">
        <span class="bold">Priority</span>
        <app-priority-and-date-selector
          *ngIf="workOrder"
          [(taskOrQuote)]="workOrder"
          [isEditable]="isEditable"
          [showRangeCalculationOption]="true"
          (changedRequiredFields)="setCanAddNewTask()">
        </app-priority-and-date-selector>        
      </div>
      <div class="ui-g-12" style="text-align: left;" *ngIf="!isCustomer">
        <button
          type="button"
          (click)="addNewWelnessTask()"
          class="smallButton green-button"
          pButton
          [disabled]="!isEditable"
          label="Add Wellness Task">
        </button>
        <button
          type="button"
          (click)="addNewWorkTask()"
          class="smallButton workTaskButton green-button"
          pButton
          [disabled]="!isEditable"
          label="Add Work Task">
        </button>
      </div>
    </div>

    <div class="p-grid p-nogutter" style="width: 100%;" *ngIf="workOrder">
      <div class="p-col-12 p-lg-4 p-nogutter" style="padding: 8px !important;" [ngClass]="{'taskComplete': wowt.wellnessTask.completionStatus == 'Completed' || wowt.wellnessTask.completionStatus =='Unable to be Completed'}" *ngFor="let wowt of workOrder.workOrderWellnessTasks">
        <app-work-order-maintenance-wellness-task-card
          [workOrderWellnessTask]="wowt"
          [isCustomer]="isCustomer"
          [isEditable]="isEditable"
          [fromQuote]="true"
          [customerId]="workOrder.customer.id"
          [quoteNumber]="workOrder.quoteNumber"
          [hidePrice]="workOrder.hidePrice"
          (copyWellnessTaskEvent)="copyWellnessTask($event)"
          (editWellnessTaskEvent)="editWellnessTask($event)"
          (deleteWellnessTaskEvent)="deleteWellnessTask($event)"
          (taskSelectedChangeEvent)="setSelectedTasks()">
        </app-work-order-maintenance-wellness-task-card>
      </div>
      <div class="p-col-12 p-lg-4 p-nogutter" style="padding: 8px !important;" [ngClass]="{'taskComplete': wowt.workTask.completionStatus == 'Completed' || wowt.workTask.completionStatus =='Unable to be Completed' }" *ngFor="let wowt of workOrder.workOrderWorkTasks">
        <app-work-order-maintenance-work-task-card
          [workOrderWorkTask]="wowt"
          [isEditable]="isEditable"
          [isCustomer]="isCustomer"
          [fromQuote]="true"
          [hidePrice]="workOrder.hidePrice"
          (copyWorkTaskEvent)="copyWorkTask($event)"
          (deleteWorkTaskEvent)="deleteWorkTask($event)"
          (editWorkTaskEvent)="editWorkTask($event)"
          (taskSelectedChangeEvent)="setSelectedTasks()">
        </app-work-order-maintenance-work-task-card>
      </div>
    </div>

    <!-- Neighbor's permission section -->
    <div class="ui-g-12">
      <div class="bold">Neighbors Permission Required: </div>
      <p-radioButton
        name="permissionRequired"
        [value]="true"
        [label]="'Yes'"
        [(ngModel)]="workOrder.neighborPermissionRequired"
        [disabled]="!isEditable"
        (ngModelChange)="neighborPermissionChange()"
        required
        *ngIf="workOrder">
      </p-radioButton>&nbsp;&nbsp;
      <p-radioButton
        name="permissionNotRequired"
        [value]="false"
        [label]="'No'"
        [(ngModel)]="workOrder.neighborPermissionRequired"
        [disabled]="!isEditable"
        (ngModelChange)="neighborPermissionChange()"
        required
        *ngIf="workOrder">
      </p-radioButton>
    </div>
    <div class="ui-g-12 ui-fluid" *ngIf="workOrder?.neighborPermissionRequired">
      <div>Enter Neighbor's Address</div>
      <p>
        <input pInputText name="neighborAddress" [(ngModel)]="workOrder.neighborPermission.address" [required]="workOrder.neighborPermissionRequired" [disabled]="!isEditable" />
      </p>
      <div>Describe Activities to be Performed on Neighbor's Property</div>
      <div>
        <input pInputText name="neighborActivities" [(ngModel)]="workOrder.neighborPermission.activiesOnNeighborProperty" [required]="workOrder.neighborPermissionRequired" [disabled]="!isEditable" />
      </div>
    </div>

    <div class="ui-g-12">
      <div class="bold">Multi-Day Job: </div>
      <p-radioButton
        name="isMultiday"
        [value]="true"
        [label]="'Yes'"
        [(ngModel)]="workOrder.isMultiday"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>&nbsp;&nbsp;
      <p-radioButton
        name="isNotMultiday"
        [value]="false"
        [label]="'No'"
        [(ngModel)]="workOrder.isMultiday"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>
    </div>

    <div class="ui-g-12">
      <div class="bold">Outside Expenses: </div>
      <p-radioButton
        name="hasRentalExpense"
        [value]="true"
        [label]="'Yes'"
        [(ngModel)]="workOrder.hasRentalExpense"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>&nbsp;&nbsp;
      <p-radioButton
        name="noRentalExpense"
        [value]="false"
        [label]="'No'"
        [(ngModel)]="workOrder.hasRentalExpense"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>
    </div>
    <div class="ui-g-12" *ngIf="workOrder?.hasRentalExpense">
      <div>Outside Cost(s)</div>
      <p>
        <input 
          pInputText 
          name="rentalCosts" 
          numeric 
          [textMask]="{mask: currencyMask}"
          [(ngModel)]="workOrder.rentalCost" 
          [required]="workOrder.hasRentalExpense" 
          [disabled]="!isEditable" 
          (ngModelChange)="workOrder.rentalCost=$event"
        />
      </p>
      <div>Describe Outside Cost(s)</div>
      <div class="ui-fluid">
        <input pInputText name="rentalDescription" [(ngModel)]="workOrder.rentalDescription" [required]="workOrder.hasRentalExpense" [disabled]="!isEditable" />
      </div>
    </div>

    <div class="ui-g-12">
      <div class="bold">Hide Price From Crew: </div>
      <p-radioButton
        name="hidePrice"
        [value]="true"
        [label]="'Yes'"
        [(ngModel)]="workOrder.hidePrice"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>&nbsp;&nbsp;
      <p-radioButton
        name="dontHidePrice"
        [value]="false"
        [label]="'No'"
        [(ngModel)]="workOrder.hidePrice"
        [disabled]="!isEditable"
        required
        *ngIf="workOrder">
      </p-radioButton>
    </div>

    <div class="ui-g" *ngIf="workOrder">
      <div class="ui-g-12">
        <h6>Quote Documents/Photos</h6>
        <app-blob-manager
          [containerName]="'quote'"
          [parentRecordId]="workOrder.id"
          [uploadLabel]="'Document/Photo'"
          [viewOnly]="!isEditable">
        </app-blob-manager>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
      <div class="ui-g" *ngIf="workOrder">
        <div class="ui-g-12">
          <h6>Message to the Customer</h6>
          <ng-select
            class="custom"
            style="margin-bottom: .5rem;"
            [searchable]="false"
            [selectOnTab]="true"
            [items]="templates"
            placeholder="Select a Message Template"
            name="template"
            bindLabel="name"
            [(ngModel)]="selectedTemplate"
            (ngModelChange)="templateChanged()"
            [disabled]="!isEditable"
            *ngIf="workOrder">
          </ng-select>
          <textarea 
            pInputTextarea 
            name="note" 
            [(ngModel)]="workOrder.note" 
            [disabled]="!isEditable"
            [rows]="5" 
            style="width: 100%;" 
            placeholder="Message to the Customer..." 
            *ngIf="workOrder"></textarea>
        </div>
        <div class="ui-g-12" *ngIf="!isCustomer">
          <h6>Message to the Crew</h6>
          <textarea 
            pInputTextarea 
            name="crewNote" 
            [(ngModel)]="workOrder.crewNote" 
            [disabled]="!isEditable"
            [rows]="5" 
            style="width: 100%;" 
            placeholder="Message to the Crew..." 
            *ngIf="workOrder"></textarea>
        </div>
      </div>
      <div class="ui-g-12 ui-md-6 multiSelect" *ngIf="!isCustomer">
        <h6>Required Skill(s)/Certification(s)</h6>
        <p-multiSelect
          [options]=""
          name="requiredSkills"
          [ngModel]="requiredSkills"
          [panelStyle]="{minWidth:'12em'}"
          optionLabel="name"
          disabled
          defaultLabel=""
          *ngIf="workOrder">
          <ng-template let-value pTemplate="selectedItems">
            <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
              <span>{{val.name}}</span>
            </div>
            <span *ngIf="!value || value.length === 0" style="width: 150px;" class="ui-multiselected-empty-token ui-corner-all">No Skills Required</span>
          </ng-template>
          <ng-template let-skill pTemplate="item">
            {{skill.label}}
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="ui-g-12 ui-md-6 multiSelect" *ngIf="!isCustomer">
        <h6>Required Equipment</h6>
        <p-multiSelect
          [options]=""
          name="requiredEquipment"
          [ngModel]="requiredEquipment"
          [panelStyle]="{minWidth:'12em'}"
          optionLabel="type"
          disabled
          defaultLabel=""
          *ngIf="workOrder">
          <ng-template let-value pTemplate="selectedItems">
            <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
              <span>{{val.type}}</span>
            </div>
            <span *ngIf="!value || value.length === 0" style="width: 180px;" class="ui-multiselected-empty-token ui-corner-all">No Equipment Required</span>
          </ng-template>
          <ng-template let-role pTemplate="item">
            {{role.label}}
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="ui-g-12">
      <!-- Common bottom section includes total information such as price adjustments, subtotal, sales tax, total -->
      <app-work-order-maintenance-common-fields-bottom
        [(workOrder)]="workOrder"
        [isEditable]="isEditable"
        [isCustomer]="isCustomer"
        [hidePrice]="workOrder.hidePrice"
        [isQuote]="true"
        (priceAdjustmentDeleted)="deletePriceAdjustment($event)"></app-work-order-maintenance-common-fields-bottom>
    </div>
  </div>
  <div class="ui-g">
    <div class="ui-g-12 columnPadding">
      <div *ngIf="workOrderForm.invalid && workOrderForm.submitted" class="alert alert-danger" role="alert">
        There are errors on this page. Please correct the errors and resubmit.
      </div>
    </div>
    <div class="ui-g-12 columnPadding">
      <app-signature-area
        *ngIf="workOrder.customerSignature"
        [isAlwaysDisabled]="!isEditable"
        [signature]="workOrder.customerSignature"
        [name]="'Customer'"
      >
      </app-signature-area>
      <div class="fine-print" style="text-align: right;" *ngIf="!isCustomer">
        Last Sent: {{workOrder.lastSent ? (workOrder.lastSent | date: 'short') : 'Not Sent'}}
      </div>
      <button pButton
              type="submit"
              class="ui-button-raised ui-button-success buttonStyle"
              (click)="save(workOrderForm)"
              [disabled]="saving || !canSave"
              *ngIf="!showReactivate"
              label="Save"></button>
      <button pButton
              type="submit"
              class="ui-button-raised ui-button-success buttonStyle"
              (click)="saveAndSend(workOrderForm)"
              [disabled]="saving || !canSave"
              *ngIf="!isCustomer && !showReactivate"
              label="Save and Send"></button>
      <button pButton
              type="submit"
              class="ui-button-raised ui-button-success buttonStyle"
              (click)="displayApproveDialog = true"
              [disabled]="saving || !canSave"
              *ngIf="!isCustomer && !showReactivate && workOrder && workOrder.id"
              label="Approve Quote"></button>
      <button pButton
              type="submit"
              class="ui-button-raised ui-button-success buttonStyle"
              (click)="reactivate()"
              [disabled]="saving || !showReactivate"
              *ngIf="!isCustomer && showReactivate"
              label="Renew Quote"></button>
      <button pButton
              type="button"
              class="ui-button-raised ui-button-warning buttonStyle"
              (click)="cancel('quote')"
              *ngIf="canSave"
              [label]="isEditable ? 'Cancel ': 'Back'"></button>
      <button pButton
              type="button"
              class="ui-button-raised ui-button-warning buttonStyle"
              (click)="cancel('quote')"
              *ngIf="!canSave"
              label="Back"></button>
      <button pButton
              type="submit"
              class="ui-button-raised buttonStyle"
              (click)="createWOs(workOrderForm)"
              *ngIf="canSign && isCustomer"
              [disabled]="!canSign || (workOrder && !workOrder.id) || (!selectedWorkTasks.length && !selectedWellnessTasks.length) || saving"
              [label]="acceptText"></button>
      <button pButton
              type="button"
              class="ui-button-raised ui-button-danger buttonStyle"
              (click)="rejectQuote()"
              *ngIf="canSign && isCustomer"
              [disabled]="!canSign || (workOrder && !workOrder.id) || saving"
              [label]="rejectText"></button>
    </div>
  </div>
</form>

<!-- Add New Wellness Task dialog -->
<!-- Only dialogs inside class scrollableDialog get the CSS for scrolling. Only use scrollableDialog if the dialog actually has enough content that
  it needs to scroll because it looks weird otherwise. -->
<div class="scrollableDialog" *ngIf="displayWellnessTaskDialog">
  <p-dialog header="Wellness Task Info" [(visible)]="displayWellnessTaskDialog" [modal]="true" [responsive]="true" [resizable]="false" [baseZIndex]="10000"
      [maximizable]="true" [style]="{'width': '760px'}" [focusOnShow]="false" (onHide)="cancelledTask()" [closable]="false" [blockScroll]="true">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all" tabindex="0" role="button" (click)="cancelledTask()" (keydown.enter)="cancelledTask()" (mousedown)="cancelledTask()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-wellness-task-maintenance 
      (timeToClose)="closedDialog($event)" 
      (cancelled)="cancelledTask($event)" 
      (delete)="deleteWellnessTaskByTask($event)"  
      [workOrder]="workOrder" 
      [task]="selectedWellnessTask"
      [showRangeCalculationOption]="true"
    ></app-wellness-task-maintenance>
  </p-dialog>
</div>

<!-- Add New Work Task dialog -->
<!-- Only dialogs inside class scrollableDialog get the CSS for scrolling. Only use scrollableDialog if the dialog actually has enough content that
  it needs to scroll because it looks weird otherwise. -->
<div class="scrollableDialog" *ngIf="displayWorkTaskDialog">
  <p-dialog header="Work Task Info" [(visible)]="displayWorkTaskDialog" [modal]="true" [responsive]="true" [resizable]="false" [baseZIndex]="10000"
    [maximizable]="true" [style]="{'width': '760px'}" [focusOnShow]="false" (onHide)="cancelledTask()" [closable]="false" [blockScroll]="true">
    <p-header style="float:right;">
      <a class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all}" tabindex="0" role="button" (click)="cancelledTask()" (keydown.enter)="cancelledTask()" (mousedown)="cancelledTask()">
        <span><i class="pi pi-times"></i></span>
      </a>
    </p-header>
    <app-work-task-maintenance 
      (timeToClose)="closedDialog($event)" 
      (cancelled)="cancelledTask($event)" 
      (delete)="deleteWorkTaskByTask($event)" 
      [workOrder]="workOrder" 
      [task]="selectedWorkTask"
      [showRangeCalculationOption]="true"
    ></app-work-task-maintenance>
  </p-dialog>
</div>

<p-dialog header="Approve" *ngIf="displayApproveDialog" [(visible)]="displayApproveDialog" [modal]="true"
  [responsive]="true" [resizable]="false" [baseZIndex]="10000" appendTo="body">
  <form>
    <div class="form-group">
      This will approve the quote without a signature and add <b>all</b> tasks to the created work order(s), please add a note on why you are not getting customer approval.
      <textarea pInputTextarea name="approvalComment" class="form-control" [required]="true"
          style="width: 100%;" autoResize="autoResize" [(ngModel)]="approvalComment"
          placeholder="Add note..."></textarea>
    </div>
    <div class="form-group">
      <button type="button" type="submit" [disabled]="!approvalComment" pButton (click)="approveWithoutCustomer()" [disabled]="isApproving"
          label="Approve"></button>&nbsp;
      <button type="button" pButton (click)="approvalComment = undefined; displayApproveDialog=false;" label="Cancel" [disabled]="isApproving"
          class="ui-button-secondary"></button>
    </div>
  </form>
</p-dialog>