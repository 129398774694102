<app-loading-swirly [isLoading]="!(currentQuoteInfo || currentWorkOrderInfo || workOrderHistoryInfo || quoteHistoryInfo)"></app-loading-swirly>
<p-accordion [multiple]="true" *ngIf="currentQuoteInfo || currentWorkOrderInfo || workOrderHistoryInfo || quoteHistoryInfo">
    <p-accordionTab *ngIf="quoteRequestInfo && quoteRequestInfo.quoteRequests && quoteRequestInfo.quoteRequests.length > 0" header="FINALIZE FIRMTIME QUOTE REQUEST(S)" [selected]="quoteRequestInfo.quoteRequests.length">
        <div *ngFor="let quote of quoteRequestInfo.quoteRequests">
            <app-customer-firmtime-quote-row [quote]="quote"></app-customer-firmtime-quote-row>
        </div>
        
        <app-loading-swirly [isLoading]="quoteRequestsLoading"></app-loading-swirly>

        <div *ngIf="quoteRequestInfo.quoteRequests.length" style="text-align: center;">
            <div class="link" *ngIf="quoteRequestInfo.quoteRequests.length < quoteRequestInfo.requestsTotal && !quoteRequestsLoading" (click)="loadMoreUnfinalizedQuoteRequests()">Load more</div>
            {{quoteRequestInfo.quoteRequests.length}} of {{quoteRequestInfo.requestsTotal}}
        </div>
    </p-accordionTab>
    <hr *ngIf="quoteRequestInfo && quoteRequestInfo.length > 0" class="smallDivider"/>
    <p-accordionTab header="NEW QUOTES" [selected]="currentQuoteInfo && currentQuoteInfo.quoteWorkOrders.length">
        <app-loading-swirly [isLoading]="!currentQuoteInfo"></app-loading-swirly>
        <span *ngIf="currentQuoteInfo">
            <div *ngIf="!currentQuoteInfo.quoteWorkOrders.length">
                You currently have no quotes to review.
            </div>
            <div *ngFor="let quote of currentQuoteInfo.quoteWorkOrders">
                <app-customer-quote-row [quote]="quote" [current]="true"></app-customer-quote-row>
            </div>            

            <app-loading-swirly [isLoading]="currentQuoteLoading"></app-loading-swirly>
            
            <div *ngIf="currentQuoteInfo.quoteWorkOrders.length" style="text-align: center;">
                <div class="link" *ngIf="currentQuoteInfo.quoteWorkOrders.length < currentQuoteInfo.quoteTotal && !currentQuoteLoading" (click)="loadMoreCurrentQuote()">Load more</div>
                {{currentQuoteInfo.quoteWorkOrders.length}} of {{currentQuoteInfo.quoteTotal}}
            </div>       
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="SCHEDULING" [selected]="currentWorkOrderInfo && (currentWorkOrderInfo.wellnessWorkOrders.length || currentWorkOrderInfo.workWorkOrders.length)">
        <div class="fine-print">
            Tree Work, Tree Wellness, and Stump Grinding are performed by separate crews so we can most efficiently and effectively complete your work. Each service is auto charged to the credit card on file separately as the work is completed.
        </div>
        <br/>
        <app-loading-swirly [isLoading]="!currentWorkOrders"></app-loading-swirly>
        <span *ngIf="currentWorkOrders">
            <div *ngIf="!currentWorkOrders.length">
                You currently have no outstanding work.
            </div>

            <div *ngFor="let workOrder of currentWorkOrders">
                <app-customer-work-order-row [workOrder]="workOrder" [current]="true"></app-customer-work-order-row>
            </div>

            <app-loading-swirly [isLoading]="currentWorkOrderLoading"></app-loading-swirly>

            <div *ngIf="currentWorkOrders.length" style="text-align: center;">
                <div class="link" *ngIf="currentWorkOrders.length < currentWorkOrderInfo.workOrderTotal && !currentWorkOrderLoading" (click)="loadMoreCurrentWorkOrders()">Load more</div>
                {{currentWorkOrders.length}} of {{currentWorkOrderInfo.workOrderTotal}}
            </div> 
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="COMPLETED WORK">
        <app-loading-swirly [isLoading]="!historicalWorkOrders"></app-loading-swirly>
        <span *ngIf="historicalWorkOrders ">
            <div *ngIf="!historicalWorkOrders.length && !historicalWorkOrders.length">
                You currently have no completed work.
            </div>
            <div *ngFor="let workOrder of historicalWorkOrders">
                <app-customer-work-order-row [workOrder]="workOrder" [workOrderBilling]="getWorkOrderBillingByWorkOrder(workOrder)" [current]="false"></app-customer-work-order-row>
            </div>
            
            <app-loading-swirly [isLoading]="historicalWorkOrderLoading"></app-loading-swirly>

            <div *ngIf="historicalWorkOrders.length" style="text-align: center;">
                <div class="link" *ngIf="historicalWorkOrders.length < workOrderHistoryInfo.workOrderTotal && !historicalWorkOrderLoading" (click)="loadMoreHistoricalWorkOrders()">Load more</div>
                {{historicalWorkOrders.length}} of {{workOrderHistoryInfo.workOrderTotal}}
            </div>           
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
    <p-accordionTab header="QUOTE HISTORY">
        <app-loading-swirly [isLoading]="!quoteHistoryInfo"></app-loading-swirly>
        <span *ngIf="quoteHistoryInfo">
            <div *ngIf="!quoteHistoryInfo.quoteWorkOrders.length">
                You currently have no completed quotes.
            </div>
            <div *ngFor="let quote of quoteHistoryInfo.quoteWorkOrders">
                <app-customer-quote-row [quote]="quote" [current]="false"></app-customer-quote-row>
            </div>
            
            <app-loading-swirly [isLoading]="quoteHistoryLoading"></app-loading-swirly>

            <div *ngIf="quoteHistoryInfo.quoteWorkOrders.length" style="text-align: center;">
                <div class="link" *ngIf="quoteHistoryInfo.quoteWorkOrders.length < quoteHistoryInfo.quoteTotal && !quoteHistoryLoading" (click)="loadMoreQuoteHistory()">Load more</div>
                {{quoteHistoryInfo.quoteWorkOrders.length}} of {{quoteHistoryInfo.quoteTotal}}
            </div>       
        </span>
    </p-accordionTab>
    <hr class="smallDivider"/>
</p-accordion>
