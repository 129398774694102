import { OnInit, EventEmitter } from '@angular/core';
import { QuoteWorkOrder, PriorityTypeShortcut, PriorityTypeShortcutGenSvc, PriorityLevelGenSvc, WellnessTask, QuoteWorkOrderManualPriorityShortcutType } from 'src/app/services_autogenerated/generated_services';
import { CalculatedPriorityType } from 'src/app/models/calculatedPriorityType';
import * as moment from 'moment';
import { Observable } from 'rxjs';
var manual = new PriorityTypeShortcut({
    name: 'Custom',
    hasCalculatedPriority: false
});
var calculated = new PriorityTypeShortcut({
    name: 'Calculated',
    hasCalculatedPriority: true
});
var PriorityAndDateSelectorComponent = /** @class */ (function () {
    function PriorityAndDateSelectorComponent(priorityTypeOptionService, priorityLevelService) {
        this.priorityTypeOptionService = priorityTypeOptionService;
        this.priorityLevelService = priorityLevelService;
        this.showRangeCalculationOption = false;
        this.changedRequiredFields = new EventEmitter();
        this.calculatedPriorityType = CalculatedPriorityType;
        this.priorityTypeOptions = [
            new PriorityTypeShortcut({
                name: CalculatedPriorityType.SoftDeadline,
                hasCalculatedPriority: true,
                hardStartDate: false,
                hardEndDate: false
            }),
            new PriorityTypeShortcut({
                name: CalculatedPriorityType.DueAfter,
                hasCalculatedPriority: true,
                hardStartDate: true,
                hardEndDate: false
            }),
            new PriorityTypeShortcut({
                name: CalculatedPriorityType.DueBefore,
                hasCalculatedPriority: true,
                hardStartDate: false,
                hardEndDate: true
            }),
            new PriorityTypeShortcut({
                name: CalculatedPriorityType.DueBetween,
                hasCalculatedPriority: true,
                hardStartDate: true,
                hardEndDate: true
            }),
            new PriorityTypeShortcut({
                name: CalculatedPriorityType.DueOn,
                hasCalculatedPriority: true,
                hardStartDate: true,
                hardEndDate: true
            }),
        ];
        this.signedQuoteWeekRangeCalculation = {
            start: undefined, end: undefined
        };
    }
    PriorityAndDateSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.showRangeCalculationOption) {
            this.priorityTypeOptions.push(new PriorityTypeShortcut({
                name: CalculatedPriorityType.WeekRange,
                hasCalculatedPriority: true,
                hardStartDate: false,
                hardEndDate: false
            }), new PriorityTypeShortcut({
                name: CalculatedPriorityType.DayRange,
                hasCalculatedPriority: true,
                hardStartDate: false,
                hardEndDate: false
            }));
        }
        if (this.taskOrQuote instanceof QuoteWorkOrder && this.taskOrQuote.signedDate) {
            this.signedQuoteWeekRangeCalculation = {
                start: moment(this.taskOrQuote.dueDateStart).diff(this.taskOrQuote.signedDate, 'w'),
                end: moment(this.taskOrQuote.dueDateEnd).diff(this.taskOrQuote.signedDate, 'w'),
            };
        }
        Observable.forkJoin(this.priorityTypeOptionService.getAll(), this.priorityLevelService.getAll())
            .subscribe(function (_a) {
            var shortcuts = _a[0], priorityLevels = _a[1];
            _this.priorityTypeOptions = shortcuts.concat(_this.priorityTypeOptions, [manual]);
            _this.allManualPriorityLevels = priorityLevels;
            var wellnessDefault = priorityLevels.find(function (pl) { return pl.status === 'Tree Wellness ' + new Date().getFullYear(); });
            if (_this.taskOrQuote instanceof WellnessTask && !_this.taskOrQuote.id && wellnessDefault) {
                _this.taskOrQuote.manualPriorityLevel = wellnessDefault;
                _this.selectedPriorityTypeOption = manual;
                _this.onPriorityTypeSelection();
                _this.onPriorityLevelSelection(wellnessDefault);
            }
            else {
                if (_this.taskOrQuote.hasCalculatedPriority === false && _this.taskOrQuote.manualPriorityLevel) {
                    _this.selectedPriorityTypeOption = manual;
                    _this.onPriorityLevelSelection(manual, false);
                }
                else if (_this.taskOrQuote.priorityShortcutId) {
                    _this.selectedPriorityTypeOption = _this.priorityTypeOptions.find(function (s) { return s.id === _this.taskOrQuote.priorityShortcutId; });
                    // Maintain due dates so if there is user saved data it won't be lost
                    _this.selectedPriorityTypeOption.dueDateStart = _this.taskOrQuote.dueDateStart;
                    _this.selectedPriorityTypeOption.dueDateEnd = _this.taskOrQuote.dueDateEnd;
                    _this.onCalculatedOptionSelection();
                }
                else if (_this.taskOrQuote.hasCalculatedPriority && (_this.taskOrQuote.dueDateEnd || _this.taskOrQuote.dueDateStart)) {
                    _this.setCalculatedPriorityOption();
                }
                else if (_this.taskOrQuote.manualPriorityShortcutType && _this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week && _this.taskOrQuote.manualRangeStart && _this.taskOrQuote.manualRangeEnd) {
                    _this.selectedPriorityTypeOption = _this.priorityTypeOptions.find(function (pto) { return pto.name === CalculatedPriorityType.WeekRange; });
                }
                else if (_this.taskOrQuote.manualPriorityShortcutType && _this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day && _this.taskOrQuote.manualRangeStart && _this.taskOrQuote.manualRangeEnd) {
                    _this.selectedPriorityTypeOption = _this.priorityTypeOptions.find(function (pto) { return pto.name === CalculatedPriorityType.DayRange; });
                }
                else {
                    // default to current backlog
                    _this.selectedPriorityTypeOption = _this.priorityTypeOptions.find(function (s) { return s.name === 'Standard'; });
                    _this.onCalculatedOptionSelection();
                }
            }
        });
    };
    PriorityAndDateSelectorComponent.prototype.setCalculatedPriorityOption = function () {
        if (this.taskOrQuote.hardStartDate && this.taskOrQuote.hardEndDate) {
            if (moment(this.taskOrQuote.dueDateStart).isSame(moment(this.taskOrQuote.dueDateEnd), 'day')) {
                this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (opt) { return opt.name === CalculatedPriorityType.DueOn; });
            }
            else {
                this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (opt) { return opt.name === CalculatedPriorityType.DueBetween; });
            }
        }
        else if (this.taskOrQuote.hardStartDate) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (opt) { return opt.name === CalculatedPriorityType.DueAfter; });
        }
        else if (this.taskOrQuote.hardEndDate) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (opt) { return opt.name === CalculatedPriorityType.DueBefore; });
        }
        else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (pto) { return pto.name === CalculatedPriorityType.WeekRange; });
        }
        else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (pto) { return pto.name === CalculatedPriorityType.DayRange; });
        }
        else {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(function (opt) { return opt.name === CalculatedPriorityType.SoftDeadline; });
        }
    };
    PriorityAndDateSelectorComponent.prototype.onPriorityTypeSelection = function () {
        this.taskOrQuote.hasCalculatedPriority = false;
        this.taskOrQuote.hardEndDate = null;
        this.taskOrQuote.hardStartDate = null;
        this.taskOrQuote.dueDateStart = null;
        this.taskOrQuote.dueDateEnd = null;
        this.taskOrQuote.priorityShortcutId = null;
        this.taskOrQuote.priorityLevelId = null;
        this.taskOrQuote.manualPriorityShortcutType = null;
        this.taskOrQuote.manualRangeStart = null;
        this.taskOrQuote.manualRangeEnd = null;
        this.taskOrQuote.priorityTypeShortcut = null;
        if (this.selectedPriorityTypeOption.hasCalculatedPriority) {
            this.onCalculatedOptionSelection();
        }
        else {
            this.onPriorityLevelSelection(this.taskOrQuote.manualPriorityLevel);
        }
        this.emitChangedEvent();
    };
    PriorityAndDateSelectorComponent.prototype.onPriorityLevelSelection = function (selectedLevel, overwrite) {
        if (overwrite === void 0) { overwrite = true; }
        this.taskOrQuote.priorityLevelId = selectedLevel ? selectedLevel.id : null;
        this.taskOrQuote.priorityLevelId = null;
        if (overwrite)
            this.taskOrQuote.dueDateStart = selectedLevel.startDate;
        if (overwrite)
            this.taskOrQuote.dueDateEnd = selectedLevel.endDate;
        if (selectedLevel && selectedLevel.status && selectedLevel.status.includes('Tree Wellness')) {
            var year = selectedLevel.status.slice(-4);
            // if year is not a number (it should be at this point but you never know), then use current year
            if (isNaN(year)) {
                year = new Date().getFullYear();
            }
            if (!this.taskOrQuote.dueDateStart)
                this.taskOrQuote.dueDateStart = new Date(year, 0, 1);
            if (!this.taskOrQuote.dueDateEnd)
                this.taskOrQuote.dueDateEnd = new Date(year, 11, 31);
        }
        this.emitChangedEvent();
    };
    PriorityAndDateSelectorComponent.prototype.onCalculatedOptionSelection = function () {
        if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.WeekRange) {
            this.taskOrQuote.manualPriorityShortcutType = QuoteWorkOrderManualPriorityShortcutType.Week;
        }
        else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DayRange) {
            this.taskOrQuote.manualPriorityShortcutType = QuoteWorkOrderManualPriorityShortcutType.Day;
        }
        else {
            this.taskOrQuote.manualPriorityShortcutType = null;
            this.taskOrQuote.manualRangeStart = null;
            this.taskOrQuote.manualRangeEnd = null;
        }
        this.taskOrQuote.hasCalculatedPriority = this.selectedPriorityTypeOption.hasCalculatedPriority;
        this.taskOrQuote.hardEndDate = this.selectedPriorityTypeOption.hardEndDate;
        this.taskOrQuote.hardStartDate = this.selectedPriorityTypeOption.hardStartDate;
        // Only set to predefined dates if the dates are null/undefined
        if (!this.taskOrQuote.dueDateStart) {
            this.taskOrQuote.dueDateStart = this.selectedPriorityTypeOption.dueDateStart;
        }
        if (!this.taskOrQuote.dueDateEnd) {
            this.taskOrQuote.dueDateEnd = this.selectedPriorityTypeOption.dueDateEnd;
        }
        this.taskOrQuote.priorityShortcutId = this.selectedPriorityTypeOption.id;
        if (!this.taskOrQuote.priorityLevelId) {
            this.taskOrQuote.priorityTypeShortcut = undefined;
        }
        this.emitChangedEvent();
    };
    PriorityAndDateSelectorComponent.prototype.emitChangedEvent = function () {
        this.changedRequiredFields.emit();
    };
    PriorityAndDateSelectorComponent.prototype.calculatedDatesChanged = function () {
        if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueAfter) {
            this.taskOrQuote.dueDateEnd = moment(this.taskOrQuote.dueDateStart).add(1, 'weeks').toDate();
        }
        else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueBefore) {
            this.taskOrQuote.dueDateStart = new Date();
        }
        else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueOn) {
            this.taskOrQuote.dueDateEnd = this.taskOrQuote.dueDateStart;
        }
        else if (this.selectedPriorityTypeOption.weeksOutStart !== undefined) {
            this.taskOrQuote.dueDateStart = moment(new Date()).add(this.selectedPriorityTypeOption.weeksOutStart, 'd').toDate();
            this.taskOrQuote.dueDateEnd = moment(new Date()).add(this.selectedPriorityTypeOption.weeksOutEnd, 'd').toDate();
        }
        this.emitChangedEvent();
    };
    PriorityAndDateSelectorComponent.prototype.shouldShowWeeksOut = function () {
        return this.selectedPriorityTypeOption
            && this.selectedPriorityTypeOption.weeksOutStart !== undefined
            && this.taskOrQuote.completionStatus != 'Work Orders Created';
    };
    PriorityAndDateSelectorComponent.prototype.shouldShowCalculatedWeeksOut = function () {
        return this.selectedPriorityTypeOption
            && this.selectedPriorityTypeOption.weeksOutStart !== undefined
            && this.taskOrQuote.completionStatus == 'Work Orders Created';
    };
    PriorityAndDateSelectorComponent.prototype.shouldShowStartDateForCalculated = function () {
        // Show start date when there is a task/quote, the calculated option was selected,
        // and the selected option is not due before (the other options all show start date)
        var showStartDate = this.taskOrQuote
            && this.selectedPriorityTypeOption
            && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.DayRange
            && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.WeekRange
            && this.selectedPriorityTypeOption.weeksOutStart === undefined
            && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueBefore;
        return showStartDate;
    };
    PriorityAndDateSelectorComponent.prototype.shouldShowEndDateForCalculated = function () {
        // Show start date when there is a task/quote, the calculated option was selected,
        // and the selected option is not due after and not due before (the other options all show end date)
        var showEndDate = this.taskOrQuote
            && this.selectedPriorityTypeOption
            && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.DayRange
            && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.WeekRange
            && this.selectedPriorityTypeOption.weeksOutStart === undefined
            && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueAfter
            && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueOn;
        return showEndDate;
    };
    return PriorityAndDateSelectorComponent;
}());
export { PriorityAndDateSelectorComponent };
