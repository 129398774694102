import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuoteWorkOrder,
  PriorityTypeShortcut,
  PriorityTypeShortcutGenSvc,
  PriorityLevelGenSvc,
  PriorityLevel,
  WorkTask,
  WellnessTask,
  QuoteWorkOrderManualPriorityShortcutType} from 'src/app/services_autogenerated/generated_services';
import { ControlContainer, NgForm } from '@angular/forms';
import { CalculatedPriorityType } from 'src/app/models/calculatedPriorityType';
import * as moment from 'moment';
import { Observable } from 'rxjs';

const manual = new PriorityTypeShortcut ({
  name: 'Custom',
  hasCalculatedPriority: false
});
const calculated = new PriorityTypeShortcut ({
  name: 'Calculated',
  hasCalculatedPriority: true
});

@Component({
  selector: 'app-priority-and-date-selector',
  templateUrl: './priority-and-date-selector.component.html',
  styleUrls: ['./priority-and-date-selector.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ] // Gets the form from the parent
    // Sourced from https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475
})
export class PriorityAndDateSelectorComponent implements OnInit {
  @Input() taskOrQuote: QuoteWorkOrder | WorkTask | WellnessTask;
  @Input() isEditable: boolean;
  @Input() showRangeCalculationOption: boolean = false;

  @Output() changedRequiredFields = new EventEmitter();

  // priorityTypeOptions: PriorityTypeShortcut[];
  selectedPriorityTypeOption: PriorityTypeShortcut;

  calculatedPriorityType = CalculatedPriorityType;

  priorityTypeOptions: PriorityTypeShortcut[] = [
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.SoftDeadline,
      hasCalculatedPriority: true,
      hardStartDate: false,
      hardEndDate: false}),
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.DueAfter,
      hasCalculatedPriority: true,
      hardStartDate: true,
      hardEndDate: false }),
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.DueBefore,
      hasCalculatedPriority: true,
      hardStartDate: false,
      hardEndDate: true }),
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.DueBetween,
      hasCalculatedPriority: true,
      hardStartDate: true,
      hardEndDate: true }),
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.DueOn,
      hasCalculatedPriority: true,
      hardStartDate: true,
      hardEndDate: true }),
  ];
  // selectedCalculatedOption: PriorityTypeShortcut;

  allManualPriorityLevels: PriorityLevel[];

  signedQuoteWeekRangeCalculation = {
    start: undefined, end: undefined
  };

  constructor(
    private priorityTypeOptionService: PriorityTypeShortcutGenSvc,
    private priorityLevelService: PriorityLevelGenSvc
  ) { }

  ngOnInit() {
    if (this.showRangeCalculationOption) {
      this.priorityTypeOptions.push(
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.WeekRange,
      hasCalculatedPriority: true,
      hardStartDate: false,
      hardEndDate: false}),
    new PriorityTypeShortcut({
      name: CalculatedPriorityType.DayRange,
      hasCalculatedPriority: true,
      hardStartDate: false,
      hardEndDate: false}))
    }
  
    if (this.taskOrQuote instanceof QuoteWorkOrder && this.taskOrQuote.signedDate) {
      this.signedQuoteWeekRangeCalculation = {
        start: moment(this.taskOrQuote.dueDateStart).diff(this.taskOrQuote.signedDate, 'w'),
        end: moment(this.taskOrQuote.dueDateEnd).diff(this.taskOrQuote.signedDate, 'w'),
      }      
    }

    Observable.forkJoin(this.priorityTypeOptionService.getAll(), this.priorityLevelService.getAll())
      .subscribe(([shortcuts, priorityLevels]) => {
        this.priorityTypeOptions = [...shortcuts, ...this.priorityTypeOptions, manual ];
        this.allManualPriorityLevels = priorityLevels;
        const wellnessDefault = priorityLevels.find(pl => pl.status === 'Tree Wellness ' + new Date().getFullYear());
        if (this.taskOrQuote instanceof WellnessTask && !this.taskOrQuote.id && wellnessDefault) {
          this.taskOrQuote.manualPriorityLevel = wellnessDefault;
          this.selectedPriorityTypeOption = manual;
          this.onPriorityTypeSelection();
          this.onPriorityLevelSelection(wellnessDefault);
        } else {
          if (this.taskOrQuote.hasCalculatedPriority === false && this.taskOrQuote.manualPriorityLevel) {
            this.selectedPriorityTypeOption = manual;
            this.onPriorityLevelSelection(manual, false);
          } else if (this.taskOrQuote.priorityShortcutId) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(s => s.id === this.taskOrQuote.priorityShortcutId);
            // Maintain due dates so if there is user saved data it won't be lost
            this.selectedPriorityTypeOption.dueDateStart = this.taskOrQuote.dueDateStart;
            this.selectedPriorityTypeOption.dueDateEnd = this.taskOrQuote.dueDateEnd;
            this.onCalculatedOptionSelection();
          } else if (this.taskOrQuote.hasCalculatedPriority && (this.taskOrQuote.dueDateEnd || this.taskOrQuote.dueDateStart)) {
            this.setCalculatedPriorityOption();
          } else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week && this.taskOrQuote.manualRangeStart && this.taskOrQuote.manualRangeEnd) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(pto => pto.name === CalculatedPriorityType.WeekRange);
          } else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day && this.taskOrQuote.manualRangeStart && this.taskOrQuote.manualRangeEnd) {
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(pto => pto.name === CalculatedPriorityType.DayRange);
          }  else {
            // default to current backlog
            this.selectedPriorityTypeOption = this.priorityTypeOptions.find(s => s.name === 'Standard');
            this.onCalculatedOptionSelection();
          }
        }
      }
    );
  }

  setCalculatedPriorityOption() {
    if (this.taskOrQuote.hardStartDate && this.taskOrQuote.hardEndDate) {
      if (moment(this.taskOrQuote.dueDateStart).isSame(moment(this.taskOrQuote.dueDateEnd), 'day')) {
        this.selectedPriorityTypeOption = this.priorityTypeOptions.find(opt => opt.name === CalculatedPriorityType.DueOn);
      } else {
        this.selectedPriorityTypeOption = this.priorityTypeOptions.find(opt => opt.name === CalculatedPriorityType.DueBetween);
      }
    } else if (this.taskOrQuote.hardStartDate) {
      this.selectedPriorityTypeOption = this.priorityTypeOptions.find(opt => opt.name === CalculatedPriorityType.DueAfter);
    } else if (this.taskOrQuote.hardEndDate) {
      this.selectedPriorityTypeOption = this.priorityTypeOptions.find(opt => opt.name === CalculatedPriorityType.DueBefore);
    } else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Week) {
      this.selectedPriorityTypeOption = this.priorityTypeOptions.find(pto => pto.name === CalculatedPriorityType.WeekRange);
    } else if (this.taskOrQuote.manualPriorityShortcutType && this.taskOrQuote.manualPriorityShortcutType === QuoteWorkOrderManualPriorityShortcutType.Day) {
      this.selectedPriorityTypeOption = this.priorityTypeOptions.find(pto => pto.name === CalculatedPriorityType.DayRange);
    } else {
      this.selectedPriorityTypeOption = this.priorityTypeOptions.find(opt => opt.name === CalculatedPriorityType.SoftDeadline);
    }
  }

  onPriorityTypeSelection() {
    this.taskOrQuote.hasCalculatedPriority = false;
    this.taskOrQuote.hardEndDate = null;
    this.taskOrQuote.hardStartDate = null;
    this.taskOrQuote.dueDateStart = null;
    this.taskOrQuote.dueDateEnd = null;
    this.taskOrQuote.priorityShortcutId = null;
    this.taskOrQuote.priorityLevelId = null;
    this.taskOrQuote.manualPriorityShortcutType = null;
    this.taskOrQuote.manualRangeStart = null;
    this.taskOrQuote.manualRangeEnd = null;
    this.taskOrQuote.priorityTypeShortcut = null;

    if (this.selectedPriorityTypeOption.hasCalculatedPriority) {
      this.onCalculatedOptionSelection();
    } else {
      this.onPriorityLevelSelection(this.taskOrQuote.manualPriorityLevel);
    }

    this.emitChangedEvent();
  }

  onPriorityLevelSelection(selectedLevel: PriorityLevel, overwrite: boolean = true) {
    this.taskOrQuote.priorityLevelId = selectedLevel ? selectedLevel.id : null;
    this.taskOrQuote.priorityLevelId = null;
    
    if (overwrite)
      this.taskOrQuote.dueDateStart = selectedLevel.startDate;
    if (overwrite)
      this.taskOrQuote.dueDateEnd = selectedLevel.endDate;

    if (selectedLevel && selectedLevel.status && selectedLevel.status.includes('Tree Wellness')) {
      let year = selectedLevel.status.slice(-4) as any;

      // if year is not a number (it should be at this point but you never know), then use current year
      if (isNaN(year)) {
        year = new Date().getFullYear();
      }
      if (!this.taskOrQuote.dueDateStart)
        this.taskOrQuote.dueDateStart = new Date(year, 0, 1);
      if (!this.taskOrQuote.dueDateEnd)
        this.taskOrQuote.dueDateEnd = new Date(year, 11, 31);
    }

    this.emitChangedEvent();
  }

  onCalculatedOptionSelection() {
    if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.WeekRange) {
      this.taskOrQuote.manualPriorityShortcutType = QuoteWorkOrderManualPriorityShortcutType.Week;
    } else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DayRange) {
      this.taskOrQuote.manualPriorityShortcutType = QuoteWorkOrderManualPriorityShortcutType.Day;
    } else {
      this.taskOrQuote.manualPriorityShortcutType = null;
      this.taskOrQuote.manualRangeStart = null;
      this.taskOrQuote.manualRangeEnd = null;
    }

    this.taskOrQuote.hasCalculatedPriority = this.selectedPriorityTypeOption.hasCalculatedPriority;
    this.taskOrQuote.hardEndDate = this.selectedPriorityTypeOption.hardEndDate;
    this.taskOrQuote.hardStartDate = this.selectedPriorityTypeOption.hardStartDate;

    // Only set to predefined dates if the dates are null/undefined
    if (!this.taskOrQuote.dueDateStart) {
      this.taskOrQuote.dueDateStart = this.selectedPriorityTypeOption.dueDateStart;
    }
    if (!this.taskOrQuote.dueDateEnd) {
      this.taskOrQuote.dueDateEnd = this.selectedPriorityTypeOption.dueDateEnd;
    }

    this.taskOrQuote.priorityShortcutId = this.selectedPriorityTypeOption.id;
    if (!this.taskOrQuote.priorityLevelId) {
      this.taskOrQuote.priorityTypeShortcut = undefined;
    }
    this.emitChangedEvent();
  }

  emitChangedEvent() {
    this.changedRequiredFields.emit();
  }

  calculatedDatesChanged() {
    if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueAfter) {
      this.taskOrQuote.dueDateEnd = moment(this.taskOrQuote.dueDateStart).add(1, 'weeks').toDate();
    } else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueBefore) {
      this.taskOrQuote.dueDateStart = new Date();
    } else if (this.selectedPriorityTypeOption.name === CalculatedPriorityType.DueOn) {
      this.taskOrQuote.dueDateEnd = this.taskOrQuote.dueDateStart;
    } else if (this.selectedPriorityTypeOption.weeksOutStart !== undefined) {
      this.taskOrQuote.dueDateStart = moment(new Date()).add(this.selectedPriorityTypeOption.weeksOutStart, 'd').toDate();
      this.taskOrQuote.dueDateEnd = moment(new Date()).add(this.selectedPriorityTypeOption.weeksOutEnd, 'd').toDate();
    }

    this.emitChangedEvent();
  }

  protected shouldShowWeeksOut(): boolean {
    return this.selectedPriorityTypeOption
    && this.selectedPriorityTypeOption.weeksOutStart !== undefined
    && this.taskOrQuote.completionStatus != 'Work Orders Created';
  }

  protected shouldShowCalculatedWeeksOut(): boolean {
    return this.selectedPriorityTypeOption
    && this.selectedPriorityTypeOption.weeksOutStart !== undefined
    && this.taskOrQuote.completionStatus == 'Work Orders Created';
  }

  protected shouldShowStartDateForCalculated(): boolean {
    // Show start date when there is a task/quote, the calculated option was selected,
    // and the selected option is not due before (the other options all show start date)
    const showStartDate: boolean = this.taskOrQuote
    && this.selectedPriorityTypeOption
    && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.DayRange
    && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.WeekRange
    && this.selectedPriorityTypeOption.weeksOutStart === undefined
    && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueBefore;

    return showStartDate;
  }

  protected shouldShowEndDateForCalculated(): boolean {
    // Show start date when there is a task/quote, the calculated option was selected,
    // and the selected option is not due after and not due before (the other options all show end date)
    const showEndDate: boolean = this.taskOrQuote
    && this.selectedPriorityTypeOption
    && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.DayRange
    && this.selectedPriorityTypeOption.name !== this.calculatedPriorityType.WeekRange
    && this.selectedPriorityTypeOption.weeksOutStart === undefined
    && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueAfter
    && this.selectedPriorityTypeOption.name !== CalculatedPriorityType.DueOn;

    return showEndDate;
  }
}
