import { SharedColorService } from './shared-color.service';
import { PriorityLevel } from '../services_autogenerated/generated_services';
import { CalculatedPriorityType } from '../models/calculatedPriorityType';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "./shared-color.service";
var redThreshold = 7;
var yellowThreshold = 21;
var black = { r: 0, g: 0, b: 0 };
var red = { r: 255, g: 0, b: 0 };
var yellow = { r: 255, g: 255, b: 0 };
var green = { r: 0, g: 255, b: 0 };
var CalculatedPriorityAgingColorService = /** @class */ (function () {
    function CalculatedPriorityAgingColorService(sharedColorService) {
        this.sharedColorService = sharedColorService;
    }
    CalculatedPriorityAgingColorService.prototype.getPriority = function (task) {
        var type = this.getCalculatedPriorityType(task);
        // Have to use .startOf so that moment isn't taking the time into consideration.
        var startDateDaysOut = moment(task.dueDateStart).diff(moment().startOf('day'), 'day');
        var endDateDaysOut = moment(task.dueDateEnd).diff(moment().startOf('day'), 'day');
        var rgb = this.getRgbColorByDaysAndType(startDateDaysOut, endDateDaysOut, type);
        if (task.priorityShortcutId === 6) {
            // storm priority is first
            return new PriorityLevel({
                id: -1,
                active: true,
                status: type.toString(),
                backgroundColor: this.sharedColorService.rgbObjectToRgbString(rgb),
                textColor: this.sharedColorService.getFontColor(rgb),
                order: -Infinity
            });
        }
        return new PriorityLevel({
            id: -1,
            active: true,
            status: type.toString(),
            backgroundColor: this.sharedColorService.rgbObjectToRgbString(rgb),
            textColor: this.sharedColorService.getFontColor(rgb),
            order: endDateDaysOut
        });
    };
    CalculatedPriorityAgingColorService.prototype.getCalculatedPriorityType = function (task) {
        if (task.priorityTypeShortcut) {
            return task.priorityTypeShortcut.name;
        }
        if (task.hardStartDate && task.hardEndDate) {
            if (moment(task.dueDateStart).isSame(moment(task.dueDateEnd), 'day')) {
                return CalculatedPriorityType.DueOn;
            }
            else {
                return CalculatedPriorityType.DueBetween;
            }
        }
        else if (task.hardStartDate) {
            return CalculatedPriorityType.DueAfter;
        }
        else if (task.hardEndDate) {
            return CalculatedPriorityType.DueBefore;
        }
        else {
            return CalculatedPriorityType.SoftDeadline;
        }
    };
    CalculatedPriorityAgingColorService.prototype.getRgbColorByDaysAndType = function (startDateDaysOut, endDateDaysOut, type) {
        if (type === CalculatedPriorityType.DueOn) {
            // Due on is red when you pass the date, yellow if the date is within 7 to 21 days away and green otherwise
            if (endDateDaysOut <= redThreshold) {
                return red;
            }
            else if (endDateDaysOut <= yellowThreshold && endDateDaysOut >= redThreshold) {
                return yellow;
            }
            else {
                return green;
            }
        }
        else if (type === CalculatedPriorityType.DueAfter) {
            // Due After looks at start date - black before, green for 3 days after, yellow at 4-5 days, red after that
            // Dealing with negatives here because this works kinda backwards from the other types
            if (startDateDaysOut > 0) {
                return black;
            }
            else if (startDateDaysOut >= -3) {
                return green;
            }
            else if (startDateDaysOut >= -5) {
                return yellow;
            }
            else {
                return red;
            }
        }
        else {
            // Black before, yellow 8-21 days before the end date, red 7 days before the end date (and after end date), green otherwise
            if (startDateDaysOut > 0) {
                return black;
            }
            else if (endDateDaysOut <= redThreshold) {
                return red;
            }
            else if (endDateDaysOut <= yellowThreshold && endDateDaysOut > redThreshold) {
                return yellow;
            }
            else {
                return green;
            }
        }
    };
    CalculatedPriorityAgingColorService.prototype.roundToNearestHalf = function (num) {
        return Math.round(num * 2) / 2;
    };
    CalculatedPriorityAgingColorService.ngInjectableDef = i0.defineInjectable({ factory: function CalculatedPriorityAgingColorService_Factory() { return new CalculatedPriorityAgingColorService(i0.inject(i1.SharedColorService)); }, token: CalculatedPriorityAgingColorService, providedIn: "root" });
    return CalculatedPriorityAgingColorService;
}());
export { CalculatedPriorityAgingColorService };
